import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Box from '@mui/material/Box';
import { signsWithDetails } from "mocks/allSigns";
import { useCurrentSign } from 'hooks/selectSign';
const findSignAndReplaceCurrentSign = (title, name) => {
  const regex = new RegExp(
    signsWithDetails.map((item) => `\\b${item.name}\\b|\\b${item.languages.en}\\b|\\b${item.languages.es}\\b`).join("|"),
    "gi"
  );

  const newText = String(title[0]).replace(regex, String(name).toUpperCase());
  if(newText){
    return newText
  }
  return title;
};


const BlockContentRenderer = ({ blocks, align, className, findSign }) => {

  const { sign } = useCurrentSign();

  const serializers = {
    types: {
      block: (props) => {
        const { children } = props;
        const { style, listItem } = props.node;
        if (style === 'normal') {
          return <p className={className} style={{ textAlign: align ? `${align}` : 'normal' }}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</p>;
        }

        if (style === 'blockquote') {
          return <blockquote className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</blockquote>;
        }

        if (style === 'h1') {
          return <h1 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h1>;
        }

        if (style === 'h2') {
          return <h2 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h2>;
        }

        if (style === 'h3') {
          return <h3 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h3>;
        }

        if (style === 'h4') {
          return <h4 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h4>;
        }

        if (style === 'h5') {
          return <h5 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h5>;
        }

        if (style === 'h6') {
          return <h6 className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</h6>;
        }

        if (listItem === 'bullet') {
          return <li className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</li>;
        }

        if (listItem === 'number') {
          return <li className={className}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</li>;
        }

        return <p className={className} style={{ textAlign: align ? `${align}` : 'normal' }}>{findSign ? findSignAndReplaceCurrentSign(children, sign?.name) : children}</p>;
      },
    },
    marks: {
      alignRight: (props) => { 
        return <span className={className} style={{ textAlign: align ? `${align}` : 'normal' }}>{props.children}</span>;
      },
    },
  };

  return blocks ? <Box component={BlockContent} blocks={blocks} className={className} 
                    sx={{ 
                      "&,ul,p,span,div":{
                        textAlign: align ? `${align}` : 'normal'
                      }
                    }}
                    serializers={serializers} 
                  /> : '';
};

export default BlockContentRenderer;
