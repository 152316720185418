import { useContext } from "react";
import { LayerContent } from "Layout/Main";
import styles from "./TermsAndConditions.module.css";
import BlockContentRenderer from "components/Block/BlockContentRenderer";
import { SanityContext } from "context/SanityContext";
const TermsAndConditions = () => {
    const { termsConditions } = useContext(SanityContext);
    return (
        <LayerContent>
            <div className={styles.modal}>
                <div className={styles.modalBody}>
                    <BlockContentRenderer blocks={termsConditions?.title} className={styles.title} />
                    <div className={styles.elements}>
                    <BlockContentRenderer blocks={termsConditions?.description} />
                    </div>
                </div>
            </div>
        </LayerContent>
    );
};

export default TermsAndConditions;