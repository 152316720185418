import styles from "./SocialsIcons.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SocialsIcons = ({ icons }) => {
    return (
        <div className={styles.contentIcons}>
            {
            icons?.map((item,index)=>
            <div key={index}>
                {
                item?.url && item?.isExternal ?
                <Link to={item.url} target="_blank" rel="noopener noreferrer">
                    <img className={styles.icon} alt={item?.name} src={item?.image} />
                </Link>
                :
                <img className={styles.icon} alt={item?.name} src={item?.image} />
                }
            </div>
            )
            }
        </div>
    );
};

SocialsIcons.defaultProps = {
    icons: []
}

SocialsIcons.propTypes = {
    icons: PropTypes.array
};

export default SocialsIcons;