import { useContext } from 'react';
import styles from "./SectionTeam.module.css";
import PropTypes from 'prop-types';
import { LayerContent } from 'Layout/Main';
import { SanityContext } from 'context/SanityContext';
import BlockContentRenderer from 'components/Block/BlockContentRenderer';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

const SectionTeam = ({ setArticleRef }) => {

    const { itemsTeam } = useContext(SanityContext);
    
    return (
        <LayerContent>
            <article id="item6" className={`${styles.article}`} ref={(ref) => setArticleRef(20, ref)}>
                <div className={styles.conte}>
                    <BlockContentRenderer blocks={itemsTeam?.title} className={styles.title} />
                    <div className={styles.contentBody}>
                        <BlockContentRenderer blocks={itemsTeam?.description} align={itemsTeam?.alignText} className={styles.loremIpsumDolorContainer1}  />
                    </div>
                </div>
                <div className={styles.members}>
                    {
                        itemsTeam?.members?.map((item, index)=>{
                            return (
                                <div key={index} className={styles.gridMember} >
                                    <div className={styles.member} >
                                        <Avatar 
                                            alt="member"
                                            src={item?.avatar} 
                                            sx={{ width: 200, height: 200, background:'var(--color-blanchedalmond)' }}
                                        />
                                        <div className={styles.memberName} >{item?.name}</div>
                                        <div className={styles.memberTitle}>{item?.title}</div>
                                        <div className={styles.icons}>
                                            {
                                            item?.socials?.map((social,index)=>{
                                                return (
                                                social?.link && social?.icon &&
                                                <Link key={index} href={social.link} target="blank">
                                                    <Avatar 
                                                        alt="icon" 
                                                        src={social.icon}
                                                        sx={{ width: 35, height: 35 }}
                                                    />
                                                </Link>
                                                )
                                            
                                            })
                                            }
                                        </div>
                                        <div className={styles.history}>
                                            <BlockContentRenderer blocks={item?.description} className={styles.description} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </article>
        </LayerContent>
    );
};

SectionTeam.propTypes = {
    setArticleRef: PropTypes.any
};

export default SectionTeam;