import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { signsReverseQualityMedium } from "mocks/allSigns";
import "./styles.css"

const MathMotionCarousel3D = ({
  frameRate = 60,
  radius = 1200,
  speed = 0.05,
  typeDevice,
  ...props
}) => {
  const [timer, setTimer] = useState(0);


  const fps = 1000 / frameRate;
  const perspective = 100;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1 / fps);
    }, fps);

    return () => {
      clearInterval(interval);
    };
  }, [fps]);


  return (
    <div className="wheel-container" >
      {signsReverseQualityMedium?.map((item, index) => {
        const radians = ((index / signsReverseQualityMedium.length) * Math.PI * 2) + (timer * speed);
        const x = Math.sin(radians) * radius;
        const y = (typeDevice === "mobile" || typeDevice === "tablet")? Math.atan(timer * Math.abs(speed) * Math.PI) * 85 + perspective:(perspective + 140);
        const z = Math.cos(radians) * radius - radius;
        const opacity = Math.cos(radians) + 1.1;
        const zIndex = Math.round(Math.cos(radians) * 100 + 2);
  

        return (
          <motion.img
            alt={`img-${index}`}
            key={index}
            src={item}
            className="card"
            style={{
                position: "absolute",
                backfaceVisibility: "hidden"
            }}
            initial={{ x: 0, z: 0, transformPerspective: "400px" }}
            animate={{ x:-x, y, z, opacity, zIndex }}
            transition={{ duration: 0, repeat: Infinity }}
          />
        );
      })}
    </div>
);
}

export default MathMotionCarousel3D;

