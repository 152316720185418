import { useContext, Fragment, useState, useCallback, useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Avatar from "@mui/material/Avatar";
import CollectionsIcon from '@mui/icons-material/Collections';
import HomeIcon from '@mui/icons-material/Home';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { loginMetamask, loginWalletConnect} from "services/connectWallet";
import { UserContext } from "context/UserContext";
import { useNavigate } from "react-router-dom";

const Wallet = ({ openDrawer, setOpenDrawer }) => {

    const navigate = useNavigate();
    const { user, setUser, message, setMessage, setOpen } = useContext(UserContext);
    const [ loadingMetamask, setLoadingMetamask ] = useState(false);
    const [ loadingWalletConnect, setLoadingWalletConnect ] = useState(false);

    const go = useCallback((route)=>  {
        setOpen(false);
        navigate(`/${route}`);
    },[navigate]);

    const handleConnect = async (type) => {
        if (loadingMetamask || loadingWalletConnect) {
          return null;
        }
        setMessage(null);
        try {
          let user = null;
          if (type === 1) {
            setLoadingMetamask(true);
            user = await loginMetamask(setLoadingMetamask,setUser)
          } else if (type === 2) {
            setLoadingWalletConnect(true);
            setOpenDrawer(false);
            user = await loginWalletConnect(setLoadingWalletConnect,setUser);
          }
          setUser(user);
          setMessage(null);
          setTimeout(() => {
            setOpenDrawer(false);
          }, 1500);
        } catch (error) {
            console.log("debug error:",error);
            setMessage(error);
        } finally {
          setLoadingMetamask(false);
          setLoadingWalletConnect(false);
        }
    };

    const disconnect = async () => {
        try {
            localStorage.removeItem('walletConnect')
            localStorage.removeItem('isMetamask')
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    let net = JSON.parse(window.localStorage.getItem('network'));
    let metamask = JSON.parse(window.localStorage.getItem('isMetamask'));
    let walletConnect = JSON.parse(window.localStorage.getItem('walletConnect'));

    useEffect(()=>{
        net = JSON.parse(window.localStorage.getItem('network'));
        metamask = JSON.parse(window.localStorage.getItem('isMetamask'));
        if(net || metamask){
            handleConnect(1);
            setOpen(false)
        }
        if(walletConnect){
            handleConnect(2);
            setOpen(false)
        }
    },[])


    return (
        <SwipeableDrawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
            sx={{
                "& .MuiDrawer-paper":{
                    background:'rgba(0,0,0,.9)',
                    boxShadow: 'inset 2px 0px 0px 0px var(--color-blanchedalmond)',
                    "@media screen and (max-width:600px)":{
                        boxShadow: 'none'
                    }
                }
            }}
        >
            <Box
                sx={{width:{ xs:'100vw',sm:'250px'}}}
            >
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center'
                    }}
                >
                    <ListItem 
                        sx={{
                            width:'100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <IconButton
                            size="small"
                            edge="start"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            sx={{
                                color: 'var(--color-blanchedalmond)',
                                fontWeight: 700
                            }}
                            onClick={() => setOpenDrawer(false)}
                        >
                        X
                        </IconButton>
                    </ListItem>
                    {
                        !user && 
                        <Fragment>
                            <ListItem button  onClick={() => handleConnect(1)} sx={{ color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)' }}>
                                {
                                loadingMetamask ? 
                                <CircularProgress size="25px" sx={{ color:'var(--color-blanchedalmond)' }} />:
                                <img  style={{ width:'35px'}}alt="" src={'/WalletlogoB.png'} />
                                }
                                &nbsp;
                                <b>
                                    Browser Wallet
                                </b>
                            </ListItem>
                            <ListItem button  onClick={() => handleConnect(2)} sx={{ color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)' }}>
                                    {
                                    loadingWalletConnect ? 
                                    <CircularProgress size="25px" sx={{ color:'var(--color-blanchedalmond)' }} />:
                                    <img  style={{ width:'35px'}}alt="" src={'/Walletlogow.png'} />
                                    }
                                    &nbsp;
                                    <b>
                                        WalletConnect
                                    </b>
                            </ListItem>
                        </Fragment>
                    }
                    {user &&
                        <Fragment>
                            <ListItem
                                sx={{ gap: '1rem', color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)',width:'100%',display: 'flex', justifyContent: 'flex-start'}} >
                                <Avatar variant="square" alt={"user"} src="https://images.persea.app/extra_files/docena/compress/Cuernos.png" sx={{height:'30px',width:'30px'}}/>
                                <b>
                                   {user?.wallet &&
                                    String(user?.wallet).substring(0,5)+ '...' +String(user?.wallet).substring(38,54)
                                   }
                                </b>
                            </ListItem>                           
                            <ListItem button  onClick={() =>go("")} 
                                sx={{  
                                    cursor: 'pointer',
                                    gap: '1rem', 
                                    color: 'var(--color-blanchedalmond)', 
                                    fontFamily: 'var(--font-libre-franklin)',
                                    width:'100%',
                                    display: 'flex', 
                                    justifyContent: 'flex-start',
                                    marginTop:'1rem',
                                    "@media screen and (min-width:599px)":{
                                        display: 'none'
                                    }
                                }} 
                            >
                                <HomeIcon  sx={{ fontSize: '30px' }}/>
                                <b>
                                    Home
                                </b>
                            </ListItem>
                            <br/>
                            <ListItem
                                onClick={()=>go("profile")}
                                sx={{  cursor: 'pointer', gap: '1rem', color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)',width:'100%',display: 'flex', justifyContent: 'flex-start'}} >
                                <CollectionsIcon  sx={{ fontSize: '30px' }}/>
                                <b>
                                  My NFTs
                                </b>
                            </ListItem>
                            <br/><br/><br/>
                            <ListItem
                                onClick={ () => { disconnect() }}
                                button sx={{color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)',width:'100%',display: 'flex', justifyContent: 'center'}} >
                                <b>
                                    <center>Desconectar</center>
                                </b>
                            </ListItem>
                        </Fragment>
                    }
                    {message &&
                     <ListItem

                        sx={{color: 'var(--color-blanchedalmond)', fontFamily: 'var(--font-libre-franklin)',width:'100%',display: 'flex', justifyContent: 'center'}} >
                        {message}
                    </ListItem>
                    }
                </List>
           </Box>
        </SwipeableDrawer>
    );
};

Wallet.propTypes = {
    openDrawer: PropTypes.bool,
    setOpenDrawer: PropTypes.func
};

export default Wallet;
