import { useState, useCallback, useContext, useEffect, Fragment, useRef } from "react";
import { LayerContent } from "Layout/Main";
import styles from "./Profile.module.css";
import PortalPopup from "components/PortalPopup";
import Share from "components/Share/Share";
import CardMedia from "@mui/material/CardMedia";
import ReactCardFlip from 'react-card-flip';
import ZodiacSign from "./components/ZodiacSign";
import MyZodiacSign from "./components/MyZodiacSign";
import CircularProgress from "@mui/material/CircularProgress";
import { UserContext } from "context/UserContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentSign } from "hooks/selectSign";
import { getNFTs } from "services/profile";
import Web3 from "web3"
import Carousel from './components/CarouselSign';
import Container from '@mui/material/Container';
import { getAllTokenIdsOfOwner } from "services/nfts";

const Profile = () => {

    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { sign, getSignByDate } = useCurrentSign();
    const [isShareOpen, setShareOpen] = useState(false);
    const [ reveal, setReveal ] = useState(false);
    const [ isOwner, setIsOwner ] = useState(false);
    const [ myNfts, setMyNFts] = useState([]);
    const [ selectedShow, setSelectedShow] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const address = searchParams.get('address');
    const [ recoverItemsUser, setRecoverItemsUser] = useState(null);
    const [ loadDataIframe, setLoadDataIframe] = useState(false);
    const [ urlIframe, setUrlIframe ] = useState(null);
    const [ video, setVideo ] = useState(false);
    const iframeRef = useRef(null);

    const handleOpenSea = (wallet) => {
        const urlOpenSeaProfile = `${process.env.REACT_APP_URL_OPENSEA}/${wallet}`;
        window.open(urlOpenSeaProfile, '_blank');
    }
    

    const openShare = useCallback(() => {
        setShareOpen(true);
    }, []);

    const closeShare = useCallback(() => {
        setShareOpen(false);
    }, []);

    const handleReveal = () => {
        setReveal(!reveal);
    }

    const handleClickBuyAnother = () => {
        window.localStorage.setItem("buy-another",true);
        setTimeout(()=>{
            navigate('/');
        },500)
    }

    const isValidAddress = (address) => {
        try {
          const web3 = new Web3();
          return web3.utils.isAddress(address);
        } catch (error) {
          console.error('Error al validar la dirección:', error);
          return false;
        }
    }

    const [ loadingProcessNFT, setLoadingProcessNFT ] = useState(false);
    let totalNFT = JSON.parse(window.localStorage.getItem('totalNFT'));

    const getMyAllTokens = async (newArr, provider, ownerAddress) => {
        const res = await getAllTokenIdsOfOwner(provider,null,ownerAddress,1);
        if(res?.length > 0){
            const newArray = [...newArr, ...res.filter((id) => !newArr.some((item) => item.token_id === id)).map(item => item)];
            setMyNFts(newArray);
            setSelectedShow({ ...newArray[0] });
        }
    }      

    const statesNFTS = (res) => {
        const newArr = res.reverse().map((item) => ({ ...item })); 
        setMyNFts(newArr);
        setSelectedShow({ ...newArr[0] });
        setIsOwner(true);
        totalNFT = JSON.parse(window.localStorage.getItem('totalNFT'));
        if (totalNFT > newArr?.length) {
            let total = null;
            let timer = 0;
            let newArrays = [];
            setLoadingProcessNFT(true);
                try{
                let interval = setInterval(async () => {
                    if (totalNFT === total) {
                        const newArr = newArrays.reverse().map((item) => ({ ...item })); 
                        statesNFTS(newArr)
                        setLoadingProcessNFT(false);
                        clearInterval(interval);
                        return null;
                    }
                    if (timer >= 16000) {
                        setLoadingProcessNFT(false);
                        clearInterval(interval);
                        getMyAllTokens(newArrays,user?.provider,user?.wallet);
                        return null;
                    }

                    const res = await getNFTs(user?.wallet);
                    newArrays = res;
                    total = res?.length;
                    timer += 2000; 
                }, 2000);
            }catch (err){
                console.log("error interval profile::",err);
            }
        }else{
            window.localStorage.setItem('totalNFT',res?.length || -1);
        }
    }

    const initGetMyNFTs = async (wallet) => {
        try {
            if(isValidAddress(wallet)){
                setLoading(true);
                const res = await  getNFTs(wallet);
                if (res?.length > 0) {
                    window.localStorage.setItem('totalNFT',res?.length)
                    statesNFTS(res)
                }else{
                    setMyNFts([]);
                    setSelectedShow(null);
                    setIsOwner(false);
                }              
            }
        } catch (e) {
            setError(e?.message || 'Has an error ocurred try to latter');
            console.log("debug error:",e);
        } finally {
            setLoading(false);
        }

    }

    const getFile = (cad) => {
        let res = cad+'';
        res = res.replace("ar://","");
        return `https://arweave.net/${res}`
    }

    useEffect(()=>{
        setReveal(false);
        if(user?.wallet){
            initGetMyNFTs(user?.wallet);
        }
    },[user])

    const handleIframeLoad = () => {
        if(iframeRef.current){
            setLoadDataIframe(true);
            setTimeout(()=>{
                const iframeElement = iframeRef.current;
                iframeElement?.contentWindow?.postMessage('getValues', '*');
                setLoadDataIframe(false);
            },3000)
        }
    };

    const handleMessage = (event) => {
        if (event.data.type === 'values') {
            const { el1, el2 } = event.data.payload;
            const numbersArray = String(el1).split('/').map(Number);
            const [numerator, denominator] = numbersArray;
            setRecoverItemsUser({
                ...recoverItemsUser,
                birthday: numbersArray !== '' ?  `${(numerator+1)}/${denominator}/${new Date().getFullYear()}` : null,
                prompt: el2 !== '' ? el2: null
            });
            setVideo(getSignByDate(`${(numerator+1)}/${denominator}/${new Date().getFullYear()}`)?.video)
        }
    };

    useEffect(() => {
        window.localStorage.removeItem('newNFT');
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []); 

    useEffect(() => {
        if(selectedShow){
            setUrlIframe(getFile(selectedShow?.metadata?.json_data?.animation_url) || null);
        }
    }, [selectedShow,user]);

    useEffect(()=>{
        if(isShareOpen){
          document.body.style.overflow = 'hidden';
        }else{
          document.body.style.overflow = 'auto';
        }
    },[ isShareOpen ])


    return (
        <LayerContent>
            <article className={`${styles.article}`}>
                <div className={styles.perfil}>
                    <div className={styles.body}>
                        <div className={styles.component3Parent}>
                            <div className={styles.component3}>
                                <ReactCardFlip isFlipped={reveal} flipDirection="horizontal">
                                    <div className={styles.contentNFT}>
                                        {
                                        (address || user?.wallet) && urlIframe ?
                                        <Fragment>
                                            <iframe 
                                                id="nftIFrame"
                                                ref={iframeRef}
                                                onLoad={handleIframeLoad}
                                                style={{ overflow: 'hidden', position:'relative',top:'-1.5px', left:'-0px' }} 
                                                className={styles.imgNFT} 
                                                src={urlIframe}
                                            />
                                            {loadDataIframe &&
                                            <div className={styles.circleLoading}
                                                style={{position:'absolute',lef:'0px',top:'0px',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
                                            >
                                                <CircularProgress size="38px" sx={{ color: 'var(--color-blanchedalmond)' }}/> 
                                            </div>
                                            }
                                        </Fragment>
                                        :
                                        <iframe 
                                            style={{ overflow: 'hidden', position:'relative',top:'-1.5px', left:'-0px' }} 
                                            className={styles.imgNFT} 
                                            src={`${process.env.REACT_APP_HTML_BASE}`}
                                        />
                                        }
                                    </div>
                        
                                    <div className={styles.contentNFT}>
                                        {
                                        !loadDataIframe && video && 
                                        <CardMedia
                                            component="video"
                                            className={styles.imgNFT}
                                            alt="MyNFT" 
                                            controlsList="nodownload noremoteplayback" 
                                            controls={false} 
                                            autoPlay={true} 
                                            loop={true} 
                                            muted={true}
                                            src={video}
                                        />
                                        }
                                        {
                                        loadDataIframe && 
                                        <div className={styles.circleLoading}>
                                            <CircularProgress size="38px" sx={{ color: 'var(--color-blanchedalmond)' }}/> 
                                        </div>
                                        }
                                    </div>
                                </ReactCardFlip>
                                {!loadingProcessNFT && myNfts?.length > 1 &&
                                <Container maxWidth="xl" sx={{width:'100%'}}> 
                                    <br/><br/>
                                    <center>
                                        <Carousel 
                                            items={myNfts} 
                                            setSelectedShow={setSelectedShow}
                                            loadDataIframe={loadDataIframe}
                                            selectedShow={selectedShow}
                                            setLoadDataIframe={setLoadDataIframe}
                                        />
                                    </center>
                                </Container>
                                }
                            </div>
                            <div className={styles.info}>
                                <div className={styles.marginInfo}/>
                                <ReactCardFlip isFlipped={reveal} flipDirection="horizontal" >
                                    <ZodiacSign
                                        item={sign}
                                        styles={styles}
                                        selectedShow={selectedShow}
                                    />
                                    <MyZodiacSign
                                        item={recoverItemsUser}
                                        styles={styles}
                                        reveal={reveal}
                                    />
                                </ReactCardFlip>
                                <div className={styles.contentButtons}>
                                    {
                                    !loadingProcessNFT ?
                                    <Fragment>
                                        { user?.wallet && isOwner && !loading && !error && myNfts?.length > 0 &&
                                        <button className={styles.revealBtn} onClick={handleReveal}>
                                            <b className={styles.share}>
                                                {reveal ? "Current Zodiac Sign" : "Reveal my Zodiac Sign"}
                                            </b>
                                        </button>
                                        }
                                        { user?.wallet && isOwner && !loading && !error && myNfts?.length > 0 && reveal &&
                                        <button className={styles.revealBtn} style={{ marginTop:'1rem' }} onClick={handleClickBuyAnother}>
                                            <b className={styles.share}>
                                                Buy another
                                            </b>
                                        </button>
                                        }
                                        <div className={styles.botnWrapper}>
                                            <button className={styles.botn}  onClick={openShare}>
                                                <img className={styles.vectorIcon} alt="Share" src="/IconShare.svg" />
                                            </button>
                                            {user?.wallet && isOwner && !loading && !error && myNfts?.length > 0 &&
                                            <button className={styles.botn} onClick={()=>{handleOpenSea(user?.wallet)}}>
                                                <img className={styles.vectorIconOpenSea} alt="Opensea" src="/OpenSeaIco.svg" />
                                            </button>
                                            }
                                        </div>
                                    </Fragment>
                                    :
                                    <div className={styles.botn} style={{background:'transparent',cursor:'auto',fontSize:'16px',display:'flex',flexDirection:'column'}}>
                                        <CircularProgress size="28px" sx={{ color: 'var(--color-blanchedalmond)'}}/> 
                                        <b>
                                        Validating your last NFT...
                                        </b>
                                    </div>                           
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isShareOpen && (
                    <PortalPopup
                        overlayColor="rgba(113, 113, 113, 0.3)"
                        placement="Centered"
                        onOutsideClick={closeShare}
                    >
                    <Share onClose={closeShare} />
                    </PortalPopup>
                )}
            </article>
        </LayerContent>
    );
};


export default Profile;