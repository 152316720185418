import { useState, useRef, useEffect } from "react";

export const useMovedSection = () => {
  const [ positionObserver, setPositionObserver ] = useState(0);
  const [ articleActive, setArticleActive ] = useState(0);
  const articlesRefs = useRef([]);

  const handleSmooth = (pos) => {
    if (articlesRefs.current[pos]) {
      articlesRefs.current[pos].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  };

  const setArticleRef = (index, ref) => {
    articlesRefs.current[index] = ref;
  };

  const observerProfile = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        try{
          const position = entry?.target?.dataset?.position || null;
          if(position != null){
            setPositionObserver(Number(position));
          }
        }catch(e){
          console.log("observer error: ",e.message);
        }
      }
    });
  }, {
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.5
  });

  useEffect(() => {
    if (articlesRefs && articlesRefs.current?.length > 0) {
      articlesRefs.current.forEach((element,index) => {
        element.dataset.position = index;
        observerProfile.observe(element);
      });
    }
    return () => {
      if (articlesRefs && articlesRefs.current?.length > 0) {
        articlesRefs.current.forEach((element,index) => {
          if(element){
            element.dataset.position = index;
            observerProfile.unobserve(element);
          }
        });
      }
    };
  }, []);

  return {
    positionObserver,
    setPositionObserver,
    articleActive,
    setArticleActive,
    articlesRefs,
    setArticleRef,
    articlesRefs,
    handleSmooth
  };
};

export default useMovedSection;
