import React from "react";
import PropTypes from "prop-types";

const valueToFind = "(icono de la billetera)";
const iconToReplaced =`<img alt="icon" src='/wallet.svg' />`;

const TextBreak = ({ text, className }) => {
    const replacedText = text.replace(valueToFind, iconToReplaced);
    const textWithLineBreaks = replacedText.replace(/\n/g, "<br>");

    return (
        <p
            className={className}
            dangerouslySetInnerHTML={{ __html: textWithLineBreaks }}
        />
    );
};

TextBreak.defaultProps = {
    text: "Hello \n\n World defaultProps"
};

TextBreak.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default TextBreak;
