import { useEffect, useState, useContext, Fragment } from "react";
import PropTypes from 'prop-types';
import styles from "./SectionBuy.module.css";
import Payment from "components/Payment/Payment";
import { useCurrentSign } from "hooks/selectSign";
import StepBar from "components/StepBar/StepBar";
import Skeleton from "@mui/material/Skeleton"
import { useBuildPrompt } from "hooks/buildPrompt";
import { FormBuyContext } from "context/FormBuyContext";
import { SanityContext } from "context/SanityContext";
import BlockContentRenderer from "components/Block/BlockContentRenderer";

const SectionBuy = ({ steps, setArticleRef, pos, currentStep, handleGoSection }) => {

    const { formDataBuy } = useContext(FormBuyContext);
    const { itemsPayment } = useContext(SanityContext);
    const { sign } = useCurrentSign(formDataBuy?.date||null);
    const [ activeStep, setActiveStep ] = useState(currentStep+1);
    const { prediction, step, error, loading } = useBuildPrompt();

    useEffect(() => {
        if(step == 4){
            setActiveStep(step)
        }
    },[step]);

    return (
        <section className={`${styles.article}`} ref={(ref)=> { setArticleRef(pos,ref) }}>
            <StepBar steps={steps} activeStep={activeStep} />
            <br/>
            <div className={styles.content}>
                <div className={`${styles.column1} ${!formDataBuy?.respondQuestion && styles.hidden}`}>
                {loading ?
                    <div style={{ position:'relative'}}>
                    <Skeleton 
                        variant="rounded" 
                        width="85vw"
                        height={455} 
                        sx={{maxWidth:'400px', background: 'var(--color-blanchedalmond)' }}
                    />
                        <div className={styles.textLoading}>
                            <b>Generating Signature Bio...</b>
                        </div>
                    </div>
                :
                <Fragment>
                    <div className={`${styles.permaContainer} ${styles.fadeIn}`}>
                        <div className={styles.backgroundPerma} >
                            <img className={styles.item2}  alt=" " src="https://images.persea.app/extra_files/docena/parchmentBackground.png" />
                        </div>
                        <div style={{ margin:'0 auto', position:'relative', width: '100%', textAlign: 'center'}}>
                            <BlockContentRenderer blocks={itemsPayment?.parchment?.title} className={styles.textTitle} />
                        </div>
                        <div className={styles.prediction} style={{ color: error ? 'red':'#000'}}>
                            {!loading && error && <Fragment><br/> {error}<br/></Fragment>}
                            {!loading && !error && 
                                <div>
                                    {
                                        !formDataBuy?.respondQuestion ?
                                        <center>...</center>
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: prediction }} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {
                    !loading && !error && prediction &&
                    <div className={styles.textBrand}>
                        <BlockContentRenderer blocks={itemsPayment?.parchment?.textBrand} />
                    </div>
                    }
                </Fragment>
                }
                </div>
                <div className={styles.column2}>
                    <div className={styles.textSing}>{sign?.textTitle}</div> 
                    <div className={styles.name}>{sign?.name}</div> 
                    <div className={styles.textBuy}>{sign?.textHistory}</div> 
                    <div className={styles.contentConstellation}>
                        <img className={styles.constellationImg} alt="" src={sign?.constellation} />
                    </div>
                    <Payment 
                        setActiveStep={setActiveStep} 
                        prediction={prediction} 
                        loadingPrompt={loading}
                        errorPrompt={error}
                        sign={sign}
                        handleGoSection={handleGoSection}
                    />
                </div>
            </div>
        </section>
    );
};

SectionBuy.propTypes = {
    SectionBuy: PropTypes.array,
    setArticleRef: PropTypes.func,
    pos: PropTypes.number,
    handleGoSection: PropTypes.func
}

export default SectionBuy;