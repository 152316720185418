import React from "react";
import PropTypes from "prop-types";
import styles from "./Main.module.css";
import Header from "./Header";
import Footer from "./Footer";

export const LayerContent = ({ children }) => {
    return (
        <div className={styles.content}>
            <div className={styles.spaceMobile}/>
            {children}
            <div className={styles.spaceMobile}/>
        </div>
    )
};

const Main = ({ children}) => {
    return (
        <div className={styles.main}>
            <Header/>
            <div className={styles.child}>
                {children}
            </div> 
            <Footer />
        </div>
    );
};

LayerContent.propTypes = {
    children: PropTypes.node
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;