import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCurrentSign } from 'hooks/selectSign';


const MyZodiacSign = ({ item, reveal,  styles }) => {

    const { birthday, prompt } = item || { birthday:null, prompt:null };
    const { getSignByDate } = useCurrentSign();
    const [ sign, setSign ] = useState(null);

    useEffect(() => {
        if(birthday){
            setSign(getSignByDate(birthday));
        }
    }, [item])

    return (
        <Fragment>
            <div style={{flex:1,width:'100%'}}/>
            <div className={styles.titleName}>
                {sign?.name}
            </div>
            <div className={styles.title}>
                {sign?.dateText}
            </div>
            <div className={styles.titleDate}>
                <i>My zodiac sign</i>
            </div>
            {birthday != null &&
            <div className={styles.contentConstellation}>
                <img className={styles.constellationImg} alt="" src={sign?.constellation} />
            </div>
            }
            {
            prompt !== 'false' && reveal && 
            <div className={styles.contentText}>
                <p className={styles.withAGreatPowerComesAGre} style={{margin:'none'}}>
                    {prompt}
                </p>
            </div>
            }
        </Fragment>
    );
};

MyZodiacSign.propTypes = {
    item: PropTypes.any,
    reveal: PropTypes.bool,
    styles: PropTypes.any
};

export default MyZodiacSign;