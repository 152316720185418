export const signsReverseQualityMedium = [
    "https://images.persea.app/extra_files/docena/medium/12.png",
    "https://images.persea.app/extra_files/docena/medium/11.png",
    "https://images.persea.app/extra_files/docena/medium/10.png",
    "https://images.persea.app/extra_files/docena/medium/9.png",
    "https://images.persea.app/extra_files/docena/medium/8.png",
    "https://images.persea.app/extra_files/docena/medium/7.png",
    "https://images.persea.app/extra_files/docena/medium/6.png",
    "https://images.persea.app/extra_files/docena/medium/5.png",
    "https://images.persea.app/extra_files/docena/medium/4.png",
    "https://images.persea.app/extra_files/docena/medium/3.png",
    "https://images.persea.app/extra_files/docena/medium/2.png",
    "https://images.persea.app/extra_files/docena/medium/1.png"
];

export const signsReverse = [
    "https://images.persea.app/extra_files/docena/compress/12.png",
    "https://images.persea.app/extra_files/docena/compress/11.png",
    "https://images.persea.app/extra_files/docena/compress/10.png",
    "https://images.persea.app/extra_files/docena/compress/9.png",
    "https://images.persea.app/extra_files/docena/compress/8.png",
    "https://images.persea.app/extra_files/docena/compress/7.png",
    "https://images.persea.app/extra_files/docena/compress/6.png",
    "https://images.persea.app/extra_files/docena/compress/5.png",
    "https://images.persea.app/extra_files/docena/compress/4.png",
    "https://images.persea.app/extra_files/docena/compress/3.png",
    "https://images.persea.app/extra_files/docena/compress/2.png",
    "https://images.persea.app/extra_files/docena/compress/1.png"
];

export const signs = [
    "https://images.persea.app/extra_files/docena/compress/1.png",
    "https://images.persea.app/extra_files/docena/compress/2.png",
    "https://images.persea.app/extra_files/docena/compress/3.png",
    "https://images.persea.app/extra_files/docena/compress/4.png",
    "https://images.persea.app/extra_files/docena/compress/5.png",
    "https://images.persea.app/extra_files/docena/compress/6.png",
    "https://images.persea.app/extra_files/docena/compress/7.png",
    "https://images.persea.app/extra_files/docena/compress/8.png",
    "https://images.persea.app/extra_files/docena/compress/9.png",
    "https://images.persea.app/extra_files/docena/compress/10.png",
    "https://images.persea.app/extra_files/docena/compress/11.png",
    "https://images.persea.app/extra_files/docena/compress/12.png"
];

export const originalSigns = [
    "https://images.persea.app/extra_files/docena/originals/1.png",
    "https://images.persea.app/extra_files/docena/originals/2.png",
    "https://images.persea.app/extra_files/docena/originals/3.png",
    "https://images.persea.app/extra_files/docena/originals/4.png",
    "https://images.persea.app/extra_files/docena/originals/5.png",
    "https://images.persea.app/extra_files/docena/originals/6.png",
    "https://images.persea.app/extra_files/docena/originals/7.png",
    "https://images.persea.app/extra_files/docena/originals/8.png",
    "https://images.persea.app/extra_files/docena/originals/9.png",
    "https://images.persea.app/extra_files/docena/originals/10.png",
    "https://images.persea.app/extra_files/docena/originals/11.png",
    "https://images.persea.app/extra_files/docena/originals/12.png"
];

export const signsVideo = [
    "/profile/1.mp4",
    "/profile/2.mp4",
    "/profile/3.mp4",
    "/profile/4.mp4",
    "/profile/5.mp4",
    "/profile/6.mp4",
    "/profile/7.mp4",
    "/profile/8.mp4",
    "/profile/9.mp4",
    "/profile/10.mp4",
    "/profile/11.mp4",
    "/profile/12.mp4"
];

export const constellations = [
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation1-Aries.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation2-Tauro.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation3-Geminis.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation4-Cancer.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation5-Leo.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation6-Virgo.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation7-Libra.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation8-Escorpio.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation9-Sagitario.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation10-Capricornio.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation11-Acuario.png",
    "https://images.persea.app/extra_files/docena/assets/contellations/constellation12-Piscis.png" 
];

const names = [ 
    {
        en: "Aries",
        es: "Aries"
    },
    {
        en: "Taurus",
        es: "Tauro"
    },
    {
        en: "Gemini",
        es: "Géminis"
    },
    {
        en: "Cancer",
        es: "Cáncer"
    },
    {
        en: "Leo",
        es: "Leo"
    },
    {
        en: "Virgo",
        es: "Virgo"
    },
    {
        en: "Libra",
        es: "Libra"
    },
    {
        en: "Scorpio",
        es: "Escorpio"
    },
    {
        en: "Sagittarius",
        es: "Sagitario"
    },
    {
        en: "Capricorn",
        es: "Capricornio"
    },
    {
        en: "Aquarius",
        es: "Acuario"
    },
    {
        en: "Pisces",
        es: "Piscis"
    }
    
];

export const signsWithDetails = [
    { 
        image: signs[0], 
        originalImage: originalSigns[0],
        video: signsVideo[0], 
        constellation: constellations[0],
        name: "Aries",
        languages: names[0],
        start: { month: 3, day: 21 }, 
        end: { month: 4, day: 19 },
        dateText: "March 21st to April 19th",
        png: "/profile/1.png",
        textTitle: "You are a fearless",
        textHistory: "Brave and daring, ready to conquer any challenge that comes your way"
    },
    { 
        image: signs[1],
        originalImage: originalSigns[1],
        video: signsVideo[1],
        constellation: constellations[1], 
        name: "Taurus", 
        languages: names[1],
        start: { month: 4, day: 20 },
        end: { month: 5, day: 20 },
        dateText: "April 20th to May 20th",
        png: "/profile/2.png",
        textTitle: "You are a reliable",
        textHistory: "Grounded and steadfast, your loyalty and determination are unwavering"
    },
    { 
        image: signs[2],
        originalImage: originalSigns[2],
        video: signsVideo[2], 
        constellation: constellations[2], 
        name: "Gemini", 
        languages: names[2],
        start: { month: 5, day: 21 }, 
        end: { month: 6, day: 20 },
        dateText: "May 21st to June 20th",
        png: "/profile/3.png",
        textTitle: "You are an adaptable",
        textHistory: "Versatile and quick-witted, you effortlessly adapt to any situation, making you a true social chameleon"
    },
    { 
        image: signs[3], 
        originalImage: originalSigns[3],
        video: signsVideo[3], 
        constellation: constellations[3], 
        name: "Cancer",
        languages: names[3], 
        start: { month: 6, day: 21 }, 
        end: { month: 7, day: 22 },
        dateText: "June 21st to July 22nd",
        png: "/profile/4.png",
        textTitle: "You are an emotional",
        textHistory: "In touch with your feelings, your empathy touches the hearts of those around you"
    },
    { 
        image: signs[4], 
        originalImage: originalSigns[4],
        video: signsVideo[4], 
        constellation: constellations[4], 
        name: "Leo", 
        languages: names[4],
        start: { month: 7, day: 23 }, 
        end: { month: 8, day: 22 },
        dateText: "July 23rd to August 22nd",
        png: "/profile/5.png",
        textTitle: "You are an energetic",
        textHistory: "Radiating charisma and vitality, your presence lights up any room you enter"
    },
    { 
        image: signs[5], 
        originalImage: originalSigns[5],
        video: signsVideo[5], 
        constellation: constellations[5], 
        name: "Virgo", 
        languages: names[5],
        start: { month: 8, day: 23 }, 
        end: { month: 9, day: 22 },
        dateText: "August 23rd to September 22nd",
        png: "/profile/6.png",
        textTitle: "You are a detailed",
        textHistory: "Meticulous and precise, your eye for perfection leaves no detail unnoticed"
    },
    { 
        image: signs[6], 
        originalImage: originalSigns[6],
        video: signsVideo[6], 
        constellation: constellations[6], 
        name: "Libra", 
        languages: names[6],
        start: { month: 9, day: 23 }, 
        end: { month: 10, day: 22 },
        dateText: "September 23rd to October 22nd",
        png: "/profile/7.png",
        textTitle: "You are a clever",
        textHistory: "Balancing intellect and charm, your wisdom leads to harmonious connections"
    },
    { 
        image: signs[7], 
        originalImage: originalSigns[7],
        video: signsVideo[7], 
        constellation: constellations[7], 
        name: "Scorpio", 
        languages: names[7],
        start: { month: 10, day: 23 }, 
        end: { month: 11, day: 21 },
        dateText: "October 23rd to November 21st",
        png: "/profile/8.png",
        textTitle: "You are a mysterious",
        textHistory: "Your enigmatic charm and depth of emotions leave a lasting impression on those you encounter"
    },
    { 
        image: signs[8], 
        originalImage: originalSigns[8],
        video: signsVideo[8], 
        constellation: constellations[8], 
        name: "Sagittarius",
        languages: names[8], 
        start: { month: 11, day: 22 }, 
        end: { month: 12, day: 21 },
        dateText: "November 22nd to December 21st",
        png: "/profile/9.png",
        textTitle: "You are an adventurous",
        textHistory: "Seeking new horizons, your spirit of exploration knows no bounds"
    },
    { 
        image: signs[9], 
        originalImage: originalSigns[9],
        video: signsVideo[9], 
        constellation: constellations[9], 
        name: "Capricorn", 
        languages: names[9],
        start: { month: 12, day: 22 }, 
        end: { month: 1, day: 19 },
        dateText: "December 22nd to January 19th",
        png: "/profile/10.png",
        textTitle: "You are an ambitious",
        textHistory: "Determined and goal-oriented, you climb mountains to achieve success"
    },
    { 
        image: signs[10],
        originalImage: originalSigns[10],
        video: signsVideo[10], 
        constellation: constellations[10], 
        name: "Aquarius", 
        languages: names[10],
        start: { month: 1, day: 20 }, 
        end: { month: 2, day: 18 },
        dateText: "January 20th to February 18th",
        png: "/profile/11.png",
        textTitle: "You are an innovative",
        textHistory: "Your unique ideas and forward-thinking spirit lead the way to a better future"
    },
    { 
        image: signs[11],
        originalImage: originalSigns[11],
        video: signsVideo[11], 
        constellation: constellations[11], 
        name: "Pisces", 
        languages: names[11],
        start: { month: 2, day: 19 }, 
        end: { month: 3, day: 20 },
        dateText: "February 19th to March 20th",
        png: "/profile/12.png",
        textTitle: "You are an intuitive",
        textHistory: "Embracing your intuition, you perceive the world through a magical lens"
    }
];

  