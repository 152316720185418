import React from "react";
import PropTypes from "prop-types";
import styles from "./ButtonScroll.module.css";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Link } from "react-scroll";
const ButtonScroll = ({ handleFunction }) => {

    const handleClick = () => {
        handleFunction();
    }

    return (
        <div className={styles.floatContent}>
            <Link
                className={styles.controls}
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={handleClick}
            >
                <div>
                    <KeyboardDoubleArrowDownIcon sx={{ fontSize: 'var(--font-size-6xl)' }} className={styles.btnNext} />
                </div>
                <div>
                Scroll   
                </div>
            </Link>
        </div>
    );
};

ButtonScroll.defaultPropTypes = {
    handleFunction:()=>{}
};

ButtonScroll.propTypes = {
    handleFunction: PropTypes.func
};

export default ButtonScroll;