import { useState, useEffect } from "react";
import { signsWithDetails } from "mocks/allSigns";

const formatDate = (date) => {

  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
};

export const useCurrentSign = (customDate=null) => {

  const [ currentSign, setCurrentSign ] = useState(null);

  const getSignByDate  = (date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    let constellation = null;

    for (const signDetail of signsWithDetails) {
      const { start, end } = signDetail;
      const startMonth = start.month;
      const startDay = start.day;
      const endMonth = end.month;
      const endDay = end.day;

      if (
        (currentMonth === startMonth && currentDay >= startDay) ||
        (currentMonth === endMonth && currentDay <= endDay) ||
        (currentMonth > startMonth && currentMonth < endMonth)
      ) {
        constellation = {
          ...signDetail
        };
        break;
      }
    }
    return constellation;
  };

  const updateCurrentSign = () => {
    let date = null;
    if(customDate !== null){
      date = new Date(customDate);
    }else{
      date = new Date();
    }
    const currentDate = date;
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    let sign = null;
    for (const signDetail of signsWithDetails) {
      const { start, end } = signDetail;
      const startMonth = start.month;
      const startDay = start.day;
      const endMonth = end.month;
      const endDay = end.day;
      if (
        (currentMonth === startMonth && currentDay >= startDay) ||
        (currentMonth === endMonth && currentDay <= endDay) ||
        (currentMonth > startMonth && currentMonth < endMonth)
      ) {
        sign = {
          ...signDetail
        };
        break;
      }
    }
    setCurrentSign(sign);
  };


  useEffect(() => {
    updateCurrentSign();
    const interval = setInterval(updateCurrentSign, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    sign: currentSign,
    getSignByDate
  };
};
