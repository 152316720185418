import { Fragment, useContext, useState, useEffect } from "react";
import styles from "./SectionGiveInfo.module.css";
import PropTypes from 'prop-types';
import { SanityContext } from "context/SanityContext";
import SectionBuy from "../SectionBuy/SectionBuy";
import { FormBuyContext } from "context/FormBuyContext";
import { useCurrentSign } from "hooks/selectSign";
import BlockContentRenderer from "components/Block/BlockContentRenderer";
import StepBar from "components/StepBar/StepBar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const defaultSteps = [
    'Select your birthday',
    'What would you like to be called?',
    'Respond to the quiz',
    'Signature Bio',
    'Complete the purchase'
];

const stepsIfChoseNo = [
    'Select your birthday',
    'What would you like to be called?',
    'Complete the purchase'
];

const SectionGiveInfo = ({ 
    articleActive, 
    setArticleRef,
    handleGoSection
}) => {
    const { formDataBuy, handleChange } = useContext(FormBuyContext);
    const { itemsQuiz, firstItem, itemsGPTSharing } = useContext(SanityContext);
    const { getSignByDate } = useCurrentSign();
    const [ sign, setSign ] = useState(null);
    const [ checkbox1, setCheckbox1 ] = useState(false);
    const [ checkbox2, setCheckbox2 ] = useState(false);
    const emptySection = 8 // Use (emptySection + index) in map , because to before existing emptySection=6 section questions
    const [ steps, setSteps ] = useState(defaultSteps);
    const [ currentStep, setCurrentStep ] = useState(2);
    const limitWords = 2;
    const limitCharacter = 40;

    const isValidInput = (inputValue) => /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/.test(inputValue);

    const handleChangeWrite = (e) => {
        const inputValue = e.target.value;
        const inputName = e.target.name;

        if (isValidInput(inputValue)) {
            const filter = inputValue.split(/\s+/).filter(value => value !== '' || value !== ' ');
            const firstWord = String(filter[0]||'')?.length;
            const secondWord = String(filter[1]||'')?.length;
            if(filter?.length > 0 && filter?.length <= limitWords && firstWord <= limitCharacter && secondWord <= limitCharacter){
                handleChange(inputValue, inputName);
            }
        } else if (inputValue === "") {
            handleChange("", inputName);
        }
    };

    const handleKeyDownName = (e) => {
        const {key} = e;
        if(key === "Enter"){
            e.target.blur();
            handleGoSection(7)
        }
    }
    
    const handleClick = ( pos, answer, numberQuiz) => () => {
        handleChange( [answer], `answer${numberQuiz}` )
        handleGoSection(pos)
    }

    const handleClickBack = (pos) => () => {
        handleGoSection(pos)
    }

    const handleCheckbox1 = () => {
        setCheckbox1(!checkbox1);
        setCheckbox2(false);
        handleChange(true,'respondQuestion');
        setSteps(defaultSteps); 
        setCurrentStep(2)
        localStorage.removeItem('folder')
    }

    const handleCheckbox2= () => {
        setCheckbox2(!checkbox2);
        setCheckbox1(false);
        handleChange(false,'respondQuestion');
        setSteps(stepsIfChoseNo); 
        setCurrentStep(1)
        localStorage.setItem("answer","no");
        localStorage.removeItem('folder')
    }

    const handleClickContinue = () => {
        if(checkbox1){
            handleGoSection(8)
        }
        if(checkbox2){
            handleGoSection(itemsQuiz?.questions?.length + emptySection)
        }
    }

    useEffect(()=>{
        if(formDataBuy?.date){
            setSign(getSignByDate(formDataBuy.date)); 
        }
    },[formDataBuy?.date])

    useEffect(()=>{
        setCheckbox1(false);
        setCheckbox2(false);
        handleChange(false,'respondQuestion');
    },[])
        
    return (
        <Fragment>
            {
            articleActive >= 6 &&
            <article id="section5" className={`${styles.article}`} ref={(ref)=> setArticleRef(6,ref)}>  
                <div>
                    <BlockContentRenderer blocks={firstItem?.title} className={styles.titleEnterName} />
                    <div className={styles.groupParent}>
                        <br/>
                        <div className={styles.contentConstellation}>
                            <img className={styles.selectedConstellation} alt="" src={sign?.constellation} />
                        </div>
                        <BlockContentRenderer blocks={firstItem?.question} className={styles.textName} />
                        <input 
                            className={styles.inputName} 
                            autoComplete="off"
                            placeholder="Enter name"
                            type="text"
                            name="name"
                            value={formDataBuy?.name || ''}
                            onChange={(e)=> handleChangeWrite(e)}
                            onKeyDown={handleKeyDownName}
                        />
                        <p className={styles.brand}>Press enter to continue!</p>
                        <div onClick={()=>{ handleGoSection(7) }} className={`${styles.itemAnswer} ${styles.onlyMobile}`}>Continue</div>
                    </div>
                </div>
            </article>
            }
            {
            articleActive === 7 &&
            <article id="section7" className={`${styles.article}`} ref={(ref)=> setArticleRef(7,ref)}>  
                <div className={styles.cont}>
                    <BlockContentRenderer blocks={itemsGPTSharing?.title} className={styles.titleSharing} />
                    <div className={styles.elements}>
                        <BlockContentRenderer blocks={itemsGPTSharing?.description} className={styles.descriptionSharing}/>
                    </div>
                    <div className={styles.contentTerms}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Checkbox 
                                id="check1"
                                size="md"
                                onChange={handleCheckbox1}
                                checked={checkbox1}
                                sx={{
                                    color: 'var(--color-blanchedalmond)',
                                    '&.Mui-checked': {
                                        color: 'var(--color-blanchedalmond)'
                                    }
                                }} 
                            />
                            <label htmlFor="check1" style={{cursor:'pointer'}}>
                                <BlockContentRenderer blocks={itemsGPTSharing?.checkboxText1} className={styles.textCheckbox} />
                            </label>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Checkbox 
                                id="check2"
                                size="md"
                                onChange={handleCheckbox2}
                                checked={checkbox2}
                                sx={{
                                    color: 'var(--color-blanchedalmond)',
                                    '&.Mui-checked': {
                                        color: 'var(--color-blanchedalmond)'
                                    }
                                }} 
                            />
                            <label htmlFor="check2" style={{cursor:'pointer'}}>
                                <BlockContentRenderer blocks={itemsGPTSharing?.checkboxText2} className={styles.textCheckbox}/>
                            </label>
                        </div>
                    </div>
                    <div className={styles.contentBtn}>
                        <button className={styles.botn4} disabled={!checkbox1 && !checkbox2} onClick={handleClickContinue}>
                            <b className={styles.crypto}>Continue</b>
                        </button>
                    </div>
                </div>
            </article>
            }
            {
            itemsQuiz?.questions?.map((item,index)=>{
                return (
                <Fragment key={index}>
                    {
                    articleActive == (emptySection +  index) &&
                    <article  className={`${styles.article} ${styles.fadeIn}`} ref={(ref)=> setArticleRef((emptySection + index),ref)}>
                        <StepBar steps={steps} activeStep={2} />
                        <div style={{ width:'100%', margin: '0 auto', maxWidth: '900px'}}>
                            <div style={{width:'100%',textAlign: item?.sectionTitle?.alignText ? `${item?.sectionTitle?.alignText}`: 'initial'}}>
                                <h1 className={styles.titleQuestion} >{item?.sectionTitle.questionText}</h1>
                            </div>
                        </div>
                        <div className={styles.contentAnswers}>
                            {
                            item?.choices?.map((answers,index2)=>{
                                return (
                                <Fragment key={index2}>
                                    <div  className={styles.itemAnswer} onClick={handleClick( (emptySection + index)+1, `${answers?.choiceText}`, index )}>{answers?.choiceText}</div>
                                </Fragment> 
                                )
                            })
                            }
                        </div>
                        {
                            articleActive >= 9 &&
                            <div className={styles.contentBackBtn}>
                                <IconButton aria-label="ArrowBackIcon" onClick={handleClickBack(articleActive-1)} >
                                    <ArrowBackIcon 
                                        sx={{ 
                                            fontSize:'45px',
                                            color:'var(--color-blanchedalmond)',
                                            boxShadow: '0px 0px 0px 1px var(--color-blanchedalmond)',
                                            borderRadius:'50% 50%',
                                            "&:hover":{
                                                boxShadow: '0px 0px 0px 3px var(--color-blanchedalmond)'
                                            }
                                        }} 
                                    />
                                </IconButton>
                            </div>
                        }
                    </article>}
                </Fragment>
                )
            })
            }
            {articleActive == ((itemsQuiz?.questions?.length + emptySection) || -100) && 
                <SectionBuy 
                    steps={steps} 
                    setArticleRef={setArticleRef}
                    pos={itemsQuiz?.questions?.length + emptySection}
                    currentStep={currentStep}
                    handleGoSection={handleGoSection}
                />
            }
        </Fragment>
    );
};


SectionGiveInfo.propTypes = {
    articleActive: PropTypes.number,
    setArticleActive: PropTypes.func,
    setArticleRef: PropTypes.func,
    handleGoSection: PropTypes.func
};

export default SectionGiveInfo;