import axios from 'axios';
import { useCallback, useState, useContext, useEffect, Fragment } from "react";
import styles from "./Payment.module.css";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { handleErrors } from 'services/messages';
import CircularProgress from "@mui/material/CircularProgress";
import { UserContext } from "context/UserContext";
import { FormBuyContext } from "context/FormBuyContext";
import PortalPopup from "components/PortalPopup";
import MakePublic from "components/MakePublic/MakePublic";
import { findAllByDomain } from 'services/collection';
import { createMetadata } from 'services/nfts';
import { convertToETH, getHistoryTx, getPriceNFT, payableMint } from 'services/blockchain';
import { PendingTxContext } from 'context/PendingTxContext';
import { savePrompt } from 'services/getPrompt';
import AlertCreditCard from 'components/AlertCreditCard/AlertCreditCard';
import { getNFTs } from 'services/profile';
import { generatePDF } from 'services/pdf'; 


const Payment = ({ setActiveStep, prediction, loadingPrompt, errorPrompt, sign, handleGoSection }) => {

    const navigate = useNavigate();
    const { resetForm } = useContext(FormBuyContext);
    const { formDataBuy } = useContext(FormBuyContext);
    const { isPending, setIsPending } = useContext(PendingTxContext);
    const { user , open, setOpen} = useContext(UserContext);
    const [ loadingCrypto, setLoadingCrypto ] = useState(false);
    const [ loadingCreditCard, setLoadingCreditCard ] = useState(false);
    const [ openQuestion, setOpenQuestion ] = useState(false);
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ formQuestion, setFormQuestion ] = useState(null);
    const [ typePay, setTapPay ] = useState(null);
    const [ collection, setCollection ] = useState(null);
    const [ uri, setURI ] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showAnswerButtons, setShowAnswerButtons] = useState(true);
    const [ price, setPrice ] = useState(null);
    const [ txSuccess, setTxSuccess] = useState(false);
    const [ txSuccessCreditCard, setTxSuccessCreditCard ] = useState(false);
    const [ txDataCreditCard, setTxDataCreditCard ] = useState(null);
    const [ errorCollection, setErrorCollection] = useState(null);
    const [ loadingCollection, setLoadingCollection] = useState(null);
    const redirectRoute = "profile";
    const [ totalNFTs, setTotalNFTs ] = useState(0);
    const [ showButtonCreditCard, setShowButtonCreditCard ] = useState(null);
    const [ understand, setUnderstand ] = useState(null);
    const [intervalId, setIntervalId] = useState(null); 
    const [ showElements, setShowElements ] = useState(false);
    let availableValidate = false;

    const handleClickBuyAnother = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setTimeout(() => {
        handleGoSection(5);
        resetForm();
      }, 500);
    };
    

    const handleClickHere = () => {
      resetForm();
      navigate(`/${redirectRoute}?address=${user.wallet}`);
    }

    const getDataPrice = async () => {
        try {
          const newPrice = await getPriceNFT();
          if (newPrice) {
            return await convertToETH(newPrice, null, process.env.REACT_APP_RPC);
          } else {
            return null;
          }
        } catch (e) {
          console.log("getDataPrice:", e);
          return null; 
        }
    };

    useEffect(() => {
        (async () => {
          const newPrice = await getDataPrice();
          setPrice(newPrice);
        })();
    }, []);

    useEffect(() => {
      const getCollection = async() => {
        try{
          setLoadingCollection(true);
          if(!collection) {
            const collections = await findAllByDomain()
            setCollection(collections[0])
            window.localStorage.setItem("collection",collections[0]?.collection_key)
          }
        } catch (e) {
          setErrorCollection(handleErrors(e).message || 'An error occurred, try again')
          console.log("getCollection::",e);
        } finally {
          setLoadingCollection(false);
        }
      }
      getCollection()
    },[collection])

    const openWallet = useCallback(()=>{
      setOpen(true);
    },[open]);

    const handleCloseQuestion = () => {
      setOpenQuestion(false);
    }

    const handleOpenQuestion = () => {
      setOpenQuestion(true);
    }

    const handleTransaction = async (tx) => {
      try {
        await getHistoryTx(user.wallet, tx.transactionHash);

        window.localStorage.setItem('totalNFT', totalNFTs + 1);
        setIsPending(true);
        setActiveStep(5);
        setLoadingCrypto(false);
        setTxSuccess(true);
    
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    const buyCrypto = () => {
      try {
        setErrorMessage(null);
        setLoadingCrypto(true);
        setTxSuccess(false);
        replaceSubstring().then(async(response) => {
          if(response.data ) {
            const doc = new DOMParser().parseFromString(collection.description, 'text/html');
            let newDescription = doc.body.textContent || "";
            let nft_file = response.data[0].url
            let metadata = {
              name : collection.name,
              description: newDescription,
              is_video : false,
              animation_url : nft_file,
              image :  collection.thumb_url.replace('https://arweave.net/', 'ar://'),
              properties : [
                {
                  "name": "Story #",
                  "value": localStorage.getItem('folder') || ''
                },
                {
                  "name": "Sign",
                  "value": formDataBuy?.sign || ''
                }
              ]
            }
            let data = await createMetadata(metadata,collection.collection_key)
            await payableMint(user.provider,collection.blockchain.blockchain_name,user.wallet,collection.collection_key,data[0]['url'])
            .then(async(tx)=>{
              console.log("init find tx::",tx);
              if(tx?.transactionHash){
                if (tx?.transactionHash) {
                  handleTransaction(tx);
                }
              }
            }).catch((e)=>{
              setErrorMessage(e)
              setLoadingCrypto(false);
            })
          } else {
            alert('Error creating metadata')
          }
        }).catch((error) => {
          console.log(error)
          setErrorMessage(handleErrors(error).message)
          setLoadingCrypto(false);
        })
      } catch (e) {
        console.log("buy crypto::",e);
      }
    };

    const buyCreditCard = () =>{
      try{
        setLoadingCreditCard(true);
        replaceSubstring().then(async(response) => {
          if(response.data ) {
            const doc = new DOMParser().parseFromString(collection.description, 'text/html');
            let newDescription = doc.body.textContent || "";
            let nft_file = response.data[0].url
            let metadata = {
              name : collection.name,
              description: newDescription,
              is_video : false,
              animation_url : nft_file,
              image : collection.thumb_url.replace('https://arweave.net/', 'ar://'),
              properties : [
                {
                  "name": "Story #",
                  "value": localStorage.getItem('folder') || ''
                },
                {
                  "name": "Sign",
                  "value": formDataBuy?.sign || ''
                }
              ]
            }
            let data = await createMetadata(metadata,collection.collection_key)
            console.log('uri credit card::',data[0]['url'])
            setURI(data[0]['url'])
            setShowButtonCreditCard(true);
            setOpenAlert(true);
            setLoadingCreditCard(false);
          } else {
            alert('Error creating metadata')
          }
        }).catch((error) => {
          console.log(error)
          setErrorMessage('Error al comprar con tarjeta de crédito');
          setLoadingCreditCard(false);
        })
      } catch (e) {
        console.log("buyCreditCard::",e);
      }
    };

    const getTotalNFT = async (wallet) => {
      try{
        const res = await  getNFTs(wallet);
        const exist = JSON.parse(window.localStorage.getItem('totalNFT'));
        setTotalNFTs(res?.length);
        if(exist === res?.length){
          window.localStorage.setItem('totalNFT',res?.length || -1);
        }else{
          window.localStorage.setItem('totalNFT',exist || -1);
        }
      } catch (e) {
        console.log("error getTotalNFT::",e);
      }
    }

    const handleInitBuy = (type) => () => {
      if(type === "crypto"){
        if(user?.wallet){
          getTotalNFT(user?.wallet);
          setTapPay("crypto");
          if(formDataBuy?.respondQuestion){
            if(formQuestion){
              buyCrypto();
            }else{
              handleOpenQuestion();
            }
          }else{
            if(formQuestion){
              buyCrypto();
            }else{
              handleOpenQuestion();
            }
          }
        }else{
          openWallet()
        }
      } else if(type === "credit-card"){
        setTapPay("credit-card");
        if(formDataBuy?.respondQuestion){
          if(formQuestion){
            buyCreditCard();
          }else{
            handleOpenQuestion();
          }
        }else{
          if(formQuestion){
            buyCreditCard();
          }else{
            handleOpenQuestion();
          }
        }
      } else {
        return null;
      }
    }

    const handleCloseAlert = () => {
      setOpenAlert(false);
    }

    const replaceSubstring = async() =>{
      const headers = {
        'X-API-Key': process.env.REACT_APP_X_API_KEY
      };
      const url = `${process.env.REACT_APP_API}/aux/replace-substring?domain=${process.env.REACT_APP_DOMAIN}`;
      let date = new Date(formDataBuy.date);
      let body = {
        "file_url": process.env.REACT_APP_HTML_BASE,
        "substrings": {
          "BIRTHDAY_MONTH": (date.getMonth()).toString(),
          "BIRTHDAY_DAY": (date.getDate()).toString(),
        }
      }
      console.log('localS folder', localStorage.getItem('folder'))
      
      if(localStorage.getItem('folder')){
        body.substrings["ZODIAC_STORY"] = localStorage.getItem('folder');
        console.log('body', body)
        if(formQuestion  == "yes" || localStorage.getItem("answer") == "yes") {
          let res = await savePrompt(String(prediction).replace("<br/>",""))
          localStorage.setItem("folder", res[0].folder_parent_id)
          body.substrings["ZODIAC_STORY"] = localStorage.getItem('folder');
        }
      }
      
      return axios.post(url, body, {headers: headers})
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw new Error(handleErrors(error).message)
      })
    }

    const handleClickGoPage = (url) => () => {
      window.open(url,"_blank");
    }

    useEffect(()=>{
      if(openAlert || openQuestion){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = 'auto';
      }
    },[ openAlert, openQuestion ])

    const fetchDataCrossMint = async () => {
      try {
        const API = 'https://development1.back.gcloud.persea.app';
        const response = await axios.get(`${API}/nft/webhook?uid=${uri}`);
        const { data } = response;
        if (data) {
          return data?.data || null;
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        return null;
      }
    };

    const validateCrossMintTx = async () => {
      const data = await fetchDataCrossMint();
      console.log("debug validateCrossMintTx data:: ",data);
      if(data) {
        const { txId, tokenId } = data;
        const network = process.env.REACT_APP_NETWORK_NAME === 'ETHEREUM' ? 'ethereum':'goerli';
        setTxSuccessCreditCard(true);
        setTxDataCreditCard({
          tx: txId,
          openseaUrls: `${process.env.REACT_APP_URL_OPENSEA}/assets/${network}/${String(collection.collection_key).toLowerCase()}/${tokenId}`
        });
        setActiveStep(5);
        setOpenAlert(false);
        stopInterval()
        return null;
      }
      availableValidate = false;
      setLoadingCreditCard(false);
    };


   
    const startInterval = () => {
      const interval = setInterval(async () => {
        console.log("Running interval...");
        if (!availableValidate) {
          availableValidate = true;
          setLoadingCreditCard(true);
          if(!openAlert){
            stopInterval();
          }
          console.log("validateCrossMintTx init");
          await validateCrossMintTx();
        }
      }, 1000);
  
      return interval; 
    };
  
    const onOk = async () => {
      availableValidate = false;
      const newIntervalId = startInterval();
      setIntervalId(newIntervalId); 
    };
  
    const stopInterval = () => {
      clearInterval(intervalId);
      setLoadingCreditCard(false);
      console.log("Stop interval."); 
    };
  
    useEffect(() => {
      setShowElements(true)
      return () => {
        stopInterval();
      };
    }, []);
  
    useEffect(() => {
      if (!openAlert) {
        stopInterval(); 
        setUnderstand(false)
      }
    }, [openAlert]);
  

    useEffect(() => {
      setUnderstand(false)
    }, []);


    return (
      <div className={styles.component11}>
        <b className={styles.title}>Payment method</b>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        {errorCollection && <div className={styles.error} style={{color:'red',fontWeight:'bold'}}>{errorCollection}</div>}
        {loadingCollection && 
        <div className={styles.botnContainer}>
          <div style={{margin:'0 auto'}}>
            <CircularProgress size="28px" sx={{ color: 'var(--color-blanchedalmond)' }} /> 
          </div>
        </div>
        }
        {!loadingCollection && !errorCollection && collection &&
          <div className={styles.botnContainer}>
            {!txSuccess && !txSuccessCreditCard ? 
            <Fragment>
              {isPending ? 
              <b className={styles.pending}>Pending Tx...</b>
              :
              <Fragment>
                <button className={styles.botn4} disabled={loadingCrypto || loadingCreditCard || loadingPrompt || errorCollection || errorPrompt} onClick={handleInitBuy("crypto")}>
                    {
                      loadingCrypto ?
                      <CircularProgress size="18px" sx={{ color: 'var(--color-gray-100)' }} /> 
                      :
                      user && user.wallet ? 
                      <b className={styles.crypto}>Crypto {price} ETH</b>
                      :
                      <b className={styles.crypto}>Connect Wallet</b>
                    }
                </button>
                { showElements && 
                <button className={styles.botn4} disabled={loadingCrypto || loadingCreditCard || loadingPrompt || errorCollection || errorPrompt} onClick={handleInitBuy("credit-card")}>
                      {
                        loadingCreditCard ?
                        <CircularProgress size="18px" sx={{ color: 'var(--color-gray-100)' }}/> 
                        :
                        <b className={styles.crypto}>Credit Card</b>
                      }
                </button>
                }
              </Fragment>
              }
            </Fragment>
            :
            <Fragment>
              {
                txDataCreditCard ?
                <div className={styles.txContent}>
                  <div style={{color:'#97DFC9',fontSize:'1.3rem'}}>
                    <i>Succesful transaction</i>
                  </div>
                  <button className={styles.botn4} style={{maxWidth:'200px'}}  onClick={handleClickGoPage(`${process.env.REACT_APP_SCAN}tx/${txDataCreditCard?.tx}`)}>
                    <b className={styles.crypto}>View transaction</b>
                  </button>
                  <button className={styles.botn4} style={{maxWidth:'200px'}}  onClick={handleClickGoPage(txDataCreditCard?.openseaUrls)}>
                    <b className={styles.crypto}>View in OpenSea</b>
                  </button>
                  <div style={{width:'100%',maxWidth:'200px',height:'100%',display:'flex',alignItems:'center',gap:'5px'}}>
                    <div style={{width:'100%',height:'2px',background:'var(--color-blanchedalmond)'}} />
                    <div style={{width:'auto',position:'relative',top:'-2.5px'}}>
                      o
                    </div>
                    <div style={{width:'100%',height:'2px',background:'var(--color-blanchedalmond)'}} />
                  </div>
                  <button className={styles.botn4} style={{maxWidth:'200px'}}  onClick={handleClickBuyAnother}>
                    <b className={styles.crypto}>Buy another</b>
                  </button>
                </div>
                :
                <div className={styles.txContent}>
                  <div style={{color:'#97DFC9',fontSize:'1.3rem'}}>
                    <i>Succesful transaction</i>
                  </div>
                  <small>
                  Go to your profile to see your NFT
                  </small>
                  <button className={styles.botn4} style={{maxWidth:'200px'}}  onClick={handleClickHere}>
                    <b className={styles.crypto}>View my NFT</b>
                  </button>
                  <div style={{width:'100%',maxWidth:'200px',height:'100%',display:'flex',alignItems:'center',gap:'5px'}}>
                    <div style={{width:'100%',height:'2px',background:'var(--color-blanchedalmond)'}} />
                    <div style={{width:'auto',position:'relative',top:'-2.5px'}}>
                      o
                    </div>
                    <div style={{width:'100%',height:'2px',background:'var(--color-blanchedalmond)'}} />
                  </div>
                  <button className={styles.botn4} style={{maxWidth:'200px'}}  onClick={handleClickBuyAnother}>
                    <b className={styles.crypto}>Buy another</b>
                  </button>
                </div>
              }
            </Fragment>
            }
          </div>
        }
        {openQuestion && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={handleCloseQuestion}
          >
            <MakePublic 
              onClose={handleCloseQuestion}
              setFormQuestion={setFormQuestion}
              prediction={prediction}
              buyCrypto={buyCrypto}
              buyCreditCard={buyCreditCard}
              typePay={typePay}
              showAnswerButtons={showAnswerButtons}
              setShowAnswerButtons={setShowAnswerButtons}
              generatePDF = {generatePDF}
              setOpenAlert={setOpenAlert}
              respondQuestion={formDataBuy?.respondQuestion}
              date={formDataBuy?.date}
              sign={sign}
            />
          </PortalPopup>
        )}
        {openAlert && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={handleCloseAlert}
          >
            <AlertCreditCard 
              onClose={handleCloseAlert}
              showButtonCreditCard={showButtonCreditCard}
              uri={uri}
              totalPrice={price}
              understand={understand}
              setUnderstand={setUnderstand}
              onOk={onOk}
            />
          </PortalPopup>
        )}
      </div>
    );
};

Payment.propTypes = {
  SectionBuy: PropTypes.array,
  prediction: PropTypes.any,
  loadingPrompt: PropTypes.bool,
  errorPrompt: PropTypes.any,
  handleGoSection: PropTypes.func
}

export default Payment;