import { createClient } from '@sanity/client';

export const clientWhiteList = createClient({
    projectId: process.env.REACT_APP_ID_SANITY_WHITELIST,
    dataset: 'production',
    apiVersion: '2022-12-04',
    useCdn: true
});

export const client = createClient({
    projectId: process.env.REACT_APP_ID_SANITY,
    dataset: 'production',
    apiVersion: '2022-12-04',
    useCdn: true
});

