
import { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getStoragePendingTx, setStoragePendingTx } from "services/localStorage";
import { getIfHaveTxt } from "services/blockchain";
import { UserContext } from "./UserContext";

export const PendingTxContext = createContext();

const PendingTxProvider = ({ children }) => {
    
    const [ isPending, setIsPending ] = useState(getStoragePendingTx());
    const { user } = useContext(UserContext);

    const validateIfExistPendingTx = async (wallet) => {
        try{
            const res = await getIfHaveTxt(process.env.REACT_APP_DOMAIN,process.env.REACT_APP_NETWORK_NAME,wallet);
            const { pending } = res;
            setIsPending(pending);
            setStoragePendingTx(pending);
            console.log("debug pending TX::",pending);
            if(pending){
                window.localStorage.setItem("newNFT",true)
                validateIfExistPendingTx(wallet);
            }
        } catch (e) {
            console.log("debug error::",e);
        }
    }

    useEffect(()=>{
        if(user && user.wallet){
            validateIfExistPendingTx(user.wallet);
        }
    },[user,isPending])

    return (
        <PendingTxContext.Provider value={{ isPending, setIsPending }}>
            {children}
        </PendingTxContext.Provider>
    );
}

PendingTxProvider.propTypes = {
    children: PropTypes.node
}

export default PendingTxProvider;
