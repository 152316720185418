import { useContext, useEffect, useState } from "react";
import { FormBuyContext } from "context/FormBuyContext";
import { SanityContext } from "context/SanityContext";
import { getPrompt } from "services/getPrompt";
import { handleErrors } from "services/messages";

export const useBuildPrompt = () => {

    const [ prediction, setPrediction ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ error, setError] = useState(null);
    const { formDataBuy } = useContext(FormBuyContext);
    const { questionPromp } = useContext(SanityContext);
    const [ step, setStep ] = useState(0);

    const addBreak = (cad) =>{
        return String(cad).replace(/\n/g, "<br/>");
    }

    const fetchPrompt = async (prompt) => {
        try {
            setLoading(true);
            //console.log('Prompt with answers ::', prompt)
            const res = await getPrompt(prompt);
            const obj = res.data
            if(obj?.result){
                localStorage.setItem("folder", obj.folderId)
                setPrediction(addBreak(obj.result));
                setStep(4);
            }else{
                setError(handleErrors(obj)?.message || 'Has an error ocurred try to latter');
            }
        } catch (e) {
            if(String(e?.response?.data?.message) === "Something went wrong generating your response"){
                setError('ChatGPT is experiencing high demand and cannot respond, please try again later');
            }else{
                if(e?.response?.status === 403){
                    setError('You have exceeded the number of attempts, please try again in 30 minutes.');
                }else{
                    setError(handleErrors(e)?.message || 'Has an error ocurred try to latter');
                }
            }
            console.log("error fetchPrompt ::",e);
        } finally {
            setLoading(false);
        }
    }

    const removeApostrophes = (str) => {
        return str.replace(/'/g, '');
    };

    const replaceText = (sanitySign) => {
        const replacements = [
          formDataBuy?.name || '',
          formDataBuy?.answers?.map((item) => item) || []
        ];
        const arr = sanitySign?.questions || [];
        if (arr?.length > 0 ) {
            const newPrompt = arr.map((item,index) => {
                const replacement = replacements[index]
                let question = item.question;
                if(replacement) {
                    if(index === 0) {
                        question = String(question).replace(/___/g,replacement)
                    } else {
                        question = String(question)
                        for (let answer of replacement) {
                            question = String(question).replace(/___/,answer)
                        }
                    }
                }
                return {
                ...item,
                question: question
                };
            });
            let sendPrompt = newPrompt?.map((item) => item.question).join(' ') || '';
            fetchPrompt(sendPrompt)
        }
    };
      
    const findSing = (signName) => {
        const normalizedSignName = removeApostrophes(signName);
        const result = questionPromp?.filter((sanity) => (sanity && String(removeApostrophes(sanity.sign)).toUpperCase() === String(normalizedSignName).toUpperCase()));
        replaceText(result[0] || []);
    }

    useEffect(() => {
        if(formDataBuy?.sign && formDataBuy?.respondQuestion){
            setError(null);
            findSing(formDataBuy.sign);
        }
    },[formDataBuy?.sign, formDataBuy?.respondQuestion]);

    return {
        prediction,
        step,
        error,
        loading
    };
  };
  