import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import AdumuInLineFont from '../assets/fonts/adumu/Adumu-Inline.ttf';

export const generatePDF = ( title,imgSing, date ,prompt ) => {
    try {
        const doc = new jsPDF();
        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();
        const maxWidth = docWidth - 20; 
       
       
        const imgBackground = new Image();
        imgBackground.src = '/background.png';
        const imgSign = new Image();
        imgSign.src = imgSing;
       
        doc.setFillColor('#000000');
        doc.setTextColor('#ffffff');
        doc.rect(0, 0, docWidth, docHeight, 'F');
        
       

        imgBackground.onload = () => {
            const imgWidth = docWidth;
            const imgHeight = docHeight
            const x = 0;
            const y = 0;
            doc.addImage(imgBackground, 'PNG', x, y, imgWidth, imgHeight);
            imgSign.onload = () => {
                const imgWidth = 100;
                const imgHeight = (imgSign.height * imgWidth) / imgSign.width;
                const x = (docWidth - imgWidth) / 2;
                const y = 10;
                doc.addImage(imgSign, 'PNG', x, y, imgWidth, imgHeight);
    
                doc.addFileToVFS("Adumu-Inline.ttf", AdumuInLineFont);
                doc.addFont("Adumu-Inline.ttf","Adumu", "Adumu Inline");
                const yTitle = imgHeight + 30;
                doc.setFontSize(25);
                doc.setTextColor('#f0e2c2');          
                const titleWidth = doc.getTextWidth(title);
                const xTitle = (docWidth - titleWidth) / 2;
                const titleLine = doc.splitTextToSize(title, titleWidth);
                doc.text(titleLine, xTitle+0.5, yTitle);
    
                const yDate = yTitle + 10;
                doc.setFontSize(10);
                doc.setTextColor('#ffffff');
                doc.setFont('helvetica','bold');
                const dateWidth = doc.getTextWidth(date);
                const xDate = (docWidth - dateWidth) / 2;
                const dateLine = doc.splitTextToSize(date, dateWidth);
                doc.text(dateLine, xDate, yDate);
              
                doc.setFont('helvetica','normal');
        
                const yPrompt = yDate + 10;
                doc.setFontSize(12);
                doc.setTextColor('#ffffff');
                const textWidth = 100;
                const xPrompt = ((maxWidth) / 2) + 10.3;
                const promptLines = doc.splitTextToSize(prompt, textWidth);
                doc.text(promptLines, xPrompt, yPrompt, {align: 'center'});
        
                doc.save('Signature Bio.pdf');
            };
        };

        return true;
    } catch (e) {
      console.log("PDF Error:", e);
    }
};
  
  