import React from 'react';

const Block = ({ blocks, styleClass }) => {

  const renderText = (children) => {
    return children.map((child, index) => {
      if (child._type === 'span') {
        const { marks, text } = child;
        let element = <React.Fragment key={index}>{text}</React.Fragment>;

        // Aplica estilos según las marcas (marks)
        marks.forEach((mark) => {
          switch (mark) {
            case 'strong':
              element = <b key={index}> {element} </b>;
              break;
            case 'em':
              element = <em key={index}> {element} </em>;
              break;
            case 'h2': <h2 key={index}>{element}</h2>
              break;
            // Añade más estilos según tus necesidades
            default:
              break;
          }
        });

        return element;
      } else {
        return null;
      }
    });
  };

  const renderBlocks = (blocks) => {
    const renderedBlocks = blocks.map((block, index) => (
      <React.Fragment key={block._key}>
        {block.children && renderText(block.children)}
        {index < blocks.length - 1 && <br />} {/* Agregamos el salto de línea */}
      </React.Fragment>
    ));

    return renderedBlocks;
  };

  return (
    <p className={styleClass}>
      {blocks && renderBlocks(blocks)}
    </p>
  );
};

export default Block;
