import { client, clientWhiteList } from "config/sanityClient";

export const header = async() => {
    return client.fetch(`*[_type == "Header"] {
        icons [] {
            name,
            href,
            external_link,
            show_icon,
            new_icon {
                asset->{
                    _id,
                    url
                },
            }
        },
        startExperience
    }`).then((data) => {
        let headerData = {
            icons: [],
            startExperience:data[0].startExperience
        };
        for (let element of data[0].icons) {
            headerData.icons.push({
                name: element.name,
                url: element.href,
                isExternal: element.external_link,
                showIcon: element.show_icon,
                imageUrl: element.new_icon.asset.url
            });
        }
        return headerData;
    }).catch((error) => { throw error; });
};

export const section1 = async() => {
    return client.fetch(`*[_type == "Section1"] {
		title,
		description,
        alignText,
        description2,
        alignText2,
    }`).then( (data) => {
        let section1 = {
            title : data[0].title,
            description : data[0].description,
            alignText: data[0].alignText,
            description2 : data[0].description2,
            alignText2: data[0].alignText2
        }
        return  section1
    }).catch((error) => { throw error})
}

export const aboutTheDrop = async() => {
    return client.fetch(`*[_type == "about_the_drop"]{
        title,
        items,
        alignText
    }`).then((data) => {
        let res = data[0];
        return res;
    }).catch((error) => { throw error});
}

export const zodiacDescription = async() => {
    return client.fetch(`*[_type == "Section2"] {
        title
    }`).then((data) => {
        let zodiacDescriptions = data.map(zodiac => {
            return {
                title: zodiac.title,
                zodiacName: zodiac.zodiacName,
                dateRange: zodiac.dateRange
            };
        });
        return zodiacDescriptions[0] || null;
    }).catch((error) => { throw error; });
}

export const openEdition = async() => {
    return client.fetch(`*[_type == "section3"] {
        description,
        alignText
    }`).then((data) => {
        let openEditionDescriptions = {
            description:data[0].description,
            alignText: data[0].alignText
        }
        return openEditionDescriptions || null;
    }).catch((error) => { throw error; });
}

export const customization = async() => {
    return client.fetch(`*[_type == "Section4"] {
        description,
        alignText
    }`).then((data) => {
        let customizationItems = {
            description:data[0].description,
            alignText: data[0].alignText
        }
        return customizationItems || null;
    }).catch((error) => { throw error; });
}

export const firstData = async () => {
    return client.fetch(`*[_type == "Section5"]`).then((data) => {
      if (data && data.length > 0) {
        const section5 = data[0];
        return {
          title: section5.title,
          question: section5.question,
        };
      } else {
        return null; 
      }
    }).catch((error) => {
      throw error;
    });
}
  
export const quiz = async() => {
    return client.fetch(`*[_type == "quiz"] {
        quizTitle,
		questions [] {
				sectionTitle {
					questionText,
					alignText
				},
				choices [] {
						choiceText,
						isCorrect
				}
		}
    }`).then((data) => {
        let quizzes = data.map(quizItem => {
            let questions = quizItem.questions.map(questionItem => {
                let choices = questionItem.choices.map(choiceItem => {
                    return {
                        choiceText: choiceItem.choiceText,
                        isCorrect: choiceItem.isCorrect
                    };
                });
                return {
                    sectionTitle:{
                        questionText: questionItem.sectionTitle.questionText,
                        alignText: questionItem.sectionTitle.alignText,
                    },
                    choices: choices
                };
            });

            return {
                sectionTitle: quizItem.quizTitle,
                questions: questions
            };
        });

        return quizzes[0] || null;
    }).catch((error) => { throw error; });
};

export const faqs = async() => {
    return client.fetch(`*[_type == "faq"]`).then((data) => {
        let faqs = data[0].faqs.map(faqItem => {
            return {
                question: faqItem.question,
                answer: faqItem.answer,
            };
        });
        return faqs;
    }).catch((error) => { throw error});
}

export const footer = async() => {
    return client.fetch(`*[_type == "footer"] {
        copyright,
        leftIcons [] {
            external_link,
            href,
            name,
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            }
        },
        rightIcons [] {
            external_link,
            href,
            name,
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            }
        },

    }`).then((data) => {
        let footer = {
            copyright : data[0].copyright,
            left : [],
            right : [],
        }
        for (let element of  data[0]['leftIcons']) {
            footer.left.push({
                isExternal : element.external_link,
                url : element.href,
                name : element.name,
                image : element['new_icon']['asset']['url']
            })
        }
        for (let element of  data[0]['rightIcons']) {
            footer.right.push({
                isExternal : element.external_link,
                url : element.href,
                name : element.name,
                image : element['new_icon']['asset']['url']
            })
        }
        return footer
    }).catch((error) => { throw error})
}


export const questionByPromp = async () => {
    return client
      .fetch(`*[_type == "promp"]{
        zodiacSign[]{
            sign,
            questions[]{
                question
            }
        }
      }`)
      .then((data) => {
        let result = data[0].zodiacSign;
        return result;
      })
      .catch((error) => {
        throw error;
      });
};  


export const termsAndCondition = async () => {
    return client
        .fetch(`*[_type == "termsAndCondition"]{
            title,
            description,
            question
        }`)
        .then((data) => {
            let result = data[0];
            return result;
        })
        .catch((error) => {
            throw error;
        });
};


export const modalShare = async () => {
    return client
        .fetch(`*[_type == "modalShare"]{
            sharingText 
        }`)
        .then((data) => {
            let result = data[0];
            return result;
        })
        .catch((error) => {
            throw error;
        });
};  

export const sharingData = async() => {
    return clientWhiteList.fetch(`*[_type == "sharingData"] {
        title,
        description,
        sharing [] {
            label,
            sharingText,
            href,
            isShareText,
            iconLeft {
                asset->{
                    _id,
                    url
                } ,
            },
            iconRight {
                asset->{
                    _id,
                    url
                } ,
            }
        },
    }`).then((data) => {
        let sharingData = {
            title : data[0].title,
            description : data[0].description,
            items : []
        }
        for (let element of  data[0]['sharing']) {
            sharingData.items.push({
                label : element.label,
                sharingText : element.sharingText,
                url : element.href,
                isShareText : element.isShareText,
                iconLeft : element['iconLeft']['asset']['url'],
                iconRight : element['iconRight']['asset']['url']
            })
        }
        return sharingData
    }).catch((error) => { throw error})
}


export const sectionPayment = async () => {
    return client
        .fetch(`*[_type == "sectionPayment"][0]{
            parchment{
                title,
                textBrand
            },
            creditCard{
                title,
                description
            },
            textOfCalendar{
              text
            }
        }`)
        .then((data) => {
            let result = data;
            return result;
        })
        .catch((error) => {
            throw error;
        });
}; 

export const sharingWithChatGPT = async () => {
    return client
        .fetch(`*[_type == "sharingWithChatGPT"]{
            title,
            description,
            checkboxText1,
            checkboxText2
        }`)
        .then((data) => {
            let result = data[0];
            return result;
        })
        .catch((error) => {
            throw error;
        });
};  

export const listOfTitles = async() => {
    return client.fetch(`*[_type == "list_of_titles"][0].titles[].title`).then((data) => {
        if(data?.length > 0){
            return data;
        }else{
            return []
        }
    }).catch((error) => { throw error});
}


export const team = async() => {
    return client.fetch(`*[_type == "team"][0]{
        title,
        description,
        alignText,
        members[]{
            "avatar": avatar.asset->url,
            title,
            name,
            description,
            socials[]{
                "icon": icon.asset->url,
                link
            }
        }
    }`).then((data) => {
        let res = data;
        return res;
    }).catch((error) => { throw error});
}

