import axios from 'axios';

export const getHistoryByWallet = async (wallet) => {
    const response = await axios.get(`${process.env.REACT_APP_API}/history?domain=${process.env.REACT_APP_DOMAIN}&wallet=${wallet}&page=0&limit=1000&order=created`);
    return response.data
}

export const cancel = async (transactionHash,typeMovement) => {
    let data = {
        transaction_hash : transactionHash,
        type_movement : typeMovement
    }
    const response = await axios.put(`${process.env.REACT_APP_API}/history?domain=${process.env.REACT_APP_DOMAIN}`,data);
    return response.data
}