import { useState, useContext } from "react";
import styles from "./Share.module.css";
import { SanityContext } from "context/SanityContext";


const buildTwitter = (text) => {
  const isHashTag = "%20%23";
  const hashtagRegex = /(^|\s)(#[^\s#]+)/g;
  const result = text.replace(hashtagRegex, (match, p1, p2) => {
    if (p2.trim() === '#') {
      return match;
    } else {
      return p1.replace("#",isHashTag ) + p2.trim()
    }
  });
  return result;
}

const Share = ({ onClose }) => {

  const [linkCopied, setLinkCopied] = useState(false);
  const { modalShareText } = useContext(SanityContext);
  
  const link = 'https://docena.art';
  const sharingText = modalShareText.sharingText;
  

  const handleFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharingText + ' ' +link)}`, "_blank");
  };

  const handleTelegram = () => {
    window.open(`https://t.me/share/url?url=${encodeURIComponent(sharingText + ' ' +link)}`, "_blank");
  };

  const handleTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(buildTwitter(sharingText) + ' ' + link)}`, "_blank");
  };

  const handleWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(sharingText + ' ' +link)}`, "_blank");
  };

  const handleLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(sharingText + ' ' +link)}`, "_blank");
  };

  const handleEmail = () => {
    const subject = "Share";
    const body = `${sharingText} ${link}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  return (
    <div className={styles.share}>
      <b className={styles.share1}>Share</b>
      <div className={styles.redes}>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon}
            alt="Facebook"
            src="/facebook.svg"
            onClick={handleFacebook}
          />
          <div className={styles.twitter}>Facebook</div>
        </div>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon1}
            alt="Twitter"
            src="/twitter.svg"
            onClick={handleTwitter}
          />
          <div className={styles.twitter}>Twitter</div>
        </div>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon}
            alt="Whatsapp"
            src="/whatsapp.svg"
            onClick={handleWhatsApp}
          />
          <div className={styles.twitter}>Whatsapp</div>
        </div>
      </div>
      <div className={styles.redes}>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon}
            alt="Telegram"
            src="/telegram.svg"
            onClick={handleTelegram}
          />
          <div className={styles.twitter}>Telegram</div>
        </div>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon}
            alt="LinkedIn"
            src="/in.svg"
            onClick={handleLinkedIn}
          />
          <div className={styles.twitter}>LinkedIn</div>
        </div>
        <div className={styles.facebook}>
          <img
            className={styles.v982D3041Icon}
            alt="Email"
            src="/email.svg"
            onClick={handleEmail}
          />
          <div className={styles.twitter}>Email</div>
        </div>
      </div>
      <div className={styles.botnParent}>
        <div id="buttonCopy" className={styles.botn} onClick={handleCopyLink}>
        <b className={styles.copyLink}>{linkCopied ? "Link Copied!" : "Copy Link"}</b>
          <img className={styles.vectorIcon2} alt="" src="/copyIcon.svg" />
        </div>
        <div id="goBack" className={styles.goBack} onClick={onClose}>Go back</div>
      </div>
    </div>
  );
};

export default Share;
