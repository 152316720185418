import { Fragment } from "react";
import PropTypes from 'prop-types';
import SectionGiveInfo from "../SectionGiveInfo/SectionGiveInfo";
import { LayerContent } from "Layout/Main";

const SectionQuestions = ({ 
  articleActive, 
  setArticleActive, 
  setArticleRef,
  handleGoSection
}) => {
  
  return (
    <Fragment>
      {articleActive > 5 &&
        <LayerContent>
            <SectionGiveInfo
              articleActive={articleActive}
              setArticleActive={setArticleActive}
              setArticleRef={setArticleRef}
              handleGoSection={handleGoSection}
            />       
        </LayerContent>
      }
    </Fragment>
  );
};

SectionQuestions.propTypes = {
  articleActive: PropTypes.number,
  setArticleActive: PropTypes.func,
  setArticleRef: PropTypes.func,
  handleGoSection: PropTypes.func
};


export default SectionQuestions;