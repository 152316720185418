import { createContext, useState } from 'react';
import PropTypes from "prop-types";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    
    const [ user, setUser ] = useState(null);
	const [ open, setOpen ] = useState(false);
    const [ message , setMessage ] = useState(null);

    return (
        <UserContext.Provider 
            value={{ 
                user,
                setUser,
                open,
                setOpen,
                message, 
                setMessage
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

UserProvider.propTypes = {
    children: PropTypes.node
}

export default UserProvider;