import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import Main from "./Layout/Main";
import SanityProvider from "context/SanityContext";
import Profile from "pages/Profile/Profile";
import FormBuyProvider from "context/FormBuyContext";
import UserProvider from "context/UserContext";
import PendingTxProvider from "context/PendingTxContext";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import SectionActiveProvider from "context/SectionActiveContext";

function App() {
  
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <SanityProvider>
      <UserProvider>
        <FormBuyProvider>
          <PendingTxProvider>
            <SectionActiveProvider>
              <Routes>
                <Route path="/" element={<Main><Home /></Main>} />
                <Route path="/profile" element={<Main><Profile /></Main>} />
                <Route path="/terms-and-conditions" element={<Main><TermsAndConditions /></Main>} />
                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
            </SectionActiveProvider>
          </PendingTxProvider>
        </FormBuyProvider>
      </UserProvider>
    </SanityProvider>
  );
}
export default App;
