import { useContext } from "react";
import styles from "./AlertCreditCard.module.css"
import PropTypes from "prop-types";
import BlockContentRenderer from "components/Block/BlockContentRenderer";
import { SanityContext } from "context/SanityContext";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import "./Crossmint.css";

const AlertCreditCard = ({  
    uri, 
    totalPrice, 
    understand, 
    setUnderstand, 
    onOk
    }) => {

    const { itemsPayment } = useContext(SanityContext);


    const whArgs = {
        uid: uri,
    };
    const whArgsSerialized = JSON.stringify(whArgs);
    

    const handleClick = () => {
        setUnderstand(true);
        onOk();
    }

    

    return (
        <div className={styles.modal}>
            <div className={styles.modalBody}>
                <BlockContentRenderer blocks={itemsPayment?.creditCard?.title} className={styles.title} />
                <div className={styles.elements}>
                    <BlockContentRenderer blocks={itemsPayment?.creditCard?.description} className={styles.description}/>
                </div>
            </div>
            <div className={styles.modalFooter}>
                <div className={styles.contentButtons}>
                    {!understand &&
                    <button className={styles.button} onClick={handleClick}>
                        <b className={styles.buttonText}>I understand</b>
                    </button>
                    }
                    {
                        understand && uri && totalPrice && 
                        <CrossmintPayButton
                            className="xmint-btn"
                            collectionId={process.env.REACT_APP_CROSSMIN_COLLECTION_ID}
                            projectId={process.env.REACT_APP_CROSSMIN_PROJECT_ID}
                            mintConfig={{"type":"erc-721","totalPrice":totalPrice,"_uriHash":uri,"quantity":"1"}}
                            environment={process.env.NODE_ENV}
                            whPassThroughArgs={whArgsSerialized}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

AlertCreditCard.propTypes = {
    onClose: PropTypes.func,
    uri: PropTypes.any, 
    totalPrice: PropTypes.any,
    understand: PropTypes.bool, 
    setUnderstand: PropTypes.func,
    onOk: PropTypes.func
};

export default AlertCreditCard;