import React, { Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import carouselStyles from './Carousel.module.css';

const ViewSign = ({ sign,styles }) => {

  return (
    <div className={styles.conte} >
      <div className={styles.groupParent}>
        <img
          className={styles.zodiacSignsIcon}
          alt="currentSign"
          src={sign?.originalImage}
        />        
        <div className={styles.loremIpsumDolorContainer1}>
          <div className={styles.paragraph}>
            <span className={styles.nameSign}>{sign?.name}</span>
            <div className={styles.constellationsContent}>
              <img className={styles.constellationIcon} alt="constellation" src={sign?.constellation} />
            </div>
            <span className={styles.dateSign}>{sign?.dateText}</span>
          </div>
        </div>
      </div>
    </div>
  )
}


const CarouselPreview = ({ 
  items,
  currentSign,
  onClick,
  children,
  styles,
  selectedItem
}) => {


  const sliderSettings = {
    dots: true,
    arrows: true,
    initialSlide: selectedItem
  };

  const handleClick = (item) => () => {
    if(item){
      onClick()
    }
  };


  return (
    <Fragment>
      {selectedItem && 
      <Slider {...sliderSettings} className={carouselStyles.slickCarousel}>
      {items?.map((item, index) => (
        <div
          key={index}
          onClick={handleClick(item)}
        >
          {item.name !== currentSign.name ? (
            <ViewSign sign={item} styles={styles} />
          ) : (
            children
          )}
        </div>
      ))}
      </Slider>
      }
    </Fragment>
  );
};

CarouselPreview.defaultProps = {
  items: [],
  currentSign: null,
  onClick: ()=>{},
  children: null,
  styles: null,
  selectedItem: 0
};

CarouselPreview.propTypes = {
  items: PropTypes.array,
  currentSign: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
  styles: PropTypes.any,
  selectedItem: PropTypes.number
};

export default CarouselPreview;