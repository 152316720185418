const URL = process.env.REACT_APP_API;
const DOMAIN = process.env.REACT_APP_DOMAIN;


export const getNFTs = (address = '') => {
    return fetch(`${URL}/nft?domain=${DOMAIN}&wallet=${address}&limit=100&page=0&order=created`,{
        headers: {
            'X-API-Key': process.env.REACT_APP_X_API_KEY
        }
    })
    .then(response => {
        return response.json();
    })
    .catch(error => {
        return error;
    });
};
