import { Fragment } from 'react';
import PropTypes from 'prop-types';

const ZodiacSign = ({ item, styles }) => {

    const { constellation, name, dateText } = item || { constellation:null, name:null, dateText:null };

    return (
        <Fragment>
            {/* <div className={styles.titleWelcome}>
                WELCOME
            </div>
            {
            selectedShow &&  selectedShow?.metadata?.json_data?.attributes && 
            <Fragment>
                <div className={styles.titleSignUser}>
                {
                selectedShow?.metadata?.json_data?.attributes.map((attr)=> attr?.value && attr?.trait_type == "Sign" && attr?.value )
                }
                </div>
                <br/>
                <br/>
            </Fragment>
            } */}
            
            <div className={styles.titleName}>
                {name}
            </div>
            <div className={styles.title}>
                {dateText}
            </div>
            <div className={styles.titleDate}>
                <i>Current zodiac sign</i>
            </div>
            <div className={styles.contentConstellation}>
                <img className={styles.constellationImg} alt="" src={constellation} />
            </div>
        </Fragment>
    );
};

ZodiacSign.propTypes = {
    item: PropTypes.any,
    reveal: PropTypes.bool,
    styles: PropTypes.any,
    selectedShow: PropTypes.any
};

export default ZodiacSign;