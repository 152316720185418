import { useContext } from 'react';
import FAQItem from './components/FAQItem';
import styles from "./FAQs.module.css";
import { SanityContext } from 'context/SanityContext';
import PropTypes from 'prop-types';

const FAQs = ({ setArticleRef }) => {

    const { itemsFAQs } = useContext(SanityContext);

    return (
        <article id="item7" className={styles.groupParent} ref={(ref) => setArticleRef(21, ref)}>
            <center>
                <h2 className={styles.title}>
                    FAQs
                </h2>
            </center>
            <div className={styles.contentFaqs}>
                {
                itemsFAQs?.map(( item, index )=> <FAQItem key={index} {...item} />)
                }
            </div>
        </article>
    );
};


FAQs.propTypes = {
    setArticleRef: PropTypes.any
};

export default FAQs;