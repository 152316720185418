import { useContext, useState, useEffect } from "react";
import styles from "./PreviewSign.module.css";
import PropTypes from 'prop-types';
import { LayerContent } from "Layout/Main";
import { useCurrentSign } from "hooks/selectSign";
import { SanityContext } from "context/SanityContext";
import BlockContentRenderer from "components/Block/BlockContentRenderer";
import { signsWithDetails } from "mocks/allSigns"
import CarouselPreview from "components/Carousel/CarouselPreview";

const PreviewSign = ({ setArticleRef }) => {
  const { itemsPreviewSing } = useContext(SanityContext);
  const { sign } = useCurrentSign();
  const [ isVideo, setIsVideo ] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0); 

  useEffect(()=>{
    setIsVideo(false)
  },[])

  

  useEffect(() => {
    const selectedIndex = signsWithDetails.findIndex(item => item?.name === sign?.name);
    if (selectedIndex !== -1) {
      setSelectedItem(selectedIndex);
    }
  }, [sign, signsWithDetails]);

  return (
    <LayerContent>
      <article id="section4" className={`${styles.article}`} ref={(ref) => setArticleRef(4, ref)}>  
        <div className={styles.conte} >
          <center>
            <BlockContentRenderer blocks={itemsPreviewSing?.title} className={styles.title} findSign={true} />
          </center>
          {
          sign && signsWithDetails.length > 0 && selectedItem > -1 &&
          <CarouselPreview 
            items={signsWithDetails}
            currentSign={sign} 
            styles={styles}
            selectedItem={selectedItem}
          >
            <div className={styles.groupParent}>
              {isVideo?
              <video className={styles.zodiacSignsIcon} alt="currentSign" controlsList="nodownload noremoteplayback" controls={false} autoPlay={true} loop={true} muted={true}>
                <source src={sign?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              :
              <img
                className={styles.zodiacSignsIcon}
                alt="currentSign"
                src={sign?.originalImage}
              />
              }           
              <div className={styles.loremIpsumDolorContainer1}>
                <div className={styles.paragraph}>
                  <span className={styles.nameSign}>{sign?.name}</span>
                  <div className={styles.constellationsContent}>
                    <img className={styles.constellationIcon} alt="constellation" src={sign?.constellation} />
                  </div>
                  <span className={styles.dateSign}>{sign?.dateText}</span>
                </div>
              </div>
            </div>
          </CarouselPreview>
          }
        </div> 
      </article>
    </LayerContent>
  );
};

PreviewSign.propTypes = {
  setArticleRef: PropTypes.func
};

export default PreviewSign;