import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./MakePublic.module.css";
import Checkbox from "@mui/material/Checkbox";
import { SanityContext } from "context/SanityContext";
import BlockContentRenderer from 'components/Block/BlockContentRenderer';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const MakePublic = ({ 
  onClose, 
  setFormQuestion, 
  prediction, 
  buyCrypto, 
  buyCreditCard,
  typePay,
  showAnswerButtons, 
  setShowAnswerButtons, 
  generatePDF, 
  setOpenAlert, 
  respondQuestion, 
  date, 
  sign
  }) => {

    const [ acceptTerms, setAcceptTerms ] = useState(false);
    const { termsConditions } = useContext(SanityContext);

    const handleAnswer = (answer) => () => {
        localStorage.setItem("answer", answer)
        setFormQuestion(answer);
        if (answer === "no") {
          setShowAnswerButtons(false);
        }else{
          if(typePay === "crypto"){
            buyCrypto();
          }else if(typePay === "credit-card"){
            buyCreditCard();
          }
          onClose();
        }


    }

    const handleChangeTerms = () => {
        setAcceptTerms(!acceptTerms);
    }

    const formatDate = (date) => {
      const DATE = new Date(date);
      const month = format(DATE, 'MMMM');
      const day = DATE.getDate();
      let dayWithSuffix;
    
      if (day === 1 || day === 21 || day === 31) {
        dayWithSuffix = `${day}st`;
      } else if (day === 2 || day === 22) {
        dayWithSuffix = `${day}nd`;
      } else if (day === 3 || day === 23) {
        dayWithSuffix = `${day}rd`;
      } else {
        dayWithSuffix = `${day}th`;
      }
    
      return String(`${month} ${dayWithSuffix}`).toUpperCase();
    };

    const handleDownload = () => {
      if(date === undefined || date === null) { return; }
      const downloaded = generatePDF( String(sign.name).toUpperCase(), sign.png, formatDate(date), prediction);
      if (downloaded){
        if(typePay === "crypto"){
          buyCrypto();
        }else if(typePay === "credit-card"){
          buyCreditCard();
        }
        onClose();
      }
    }

    const handleContinue= () => {
      if(typePay === "crypto"){
        buyCrypto();
      }else if(typePay === "credit-card"){
        buyCreditCard();
      }
      onClose();
    }
  


    

    return (
        <div className={styles.modal}>
            <div className={styles.modalBody}>
                <div className={styles.question}>
                  {respondQuestion && 
                    <BlockContentRenderer blocks={termsConditions?.question} />
                  }
                </div>
                <div className={styles.contentTerms}>
                    <Checkbox 
                        size="md"
                        onChange={handleChangeTerms}
                        value={acceptTerms}
                        sx={{
                            color: 'var(--color-blanchedalmond)',
                            '&.Mui-checked': {
                                color: 'var(--color-blanchedalmond)'
                            }
                        }} 
                    />
                    <Link 
                    to="/terms-and-conditions"
                    target="_blank"
                    style={{
                      color:'var(--color-blanchedalmond)'
                    }}
                    >
                    <span style={{cursor:'pinter',textAlign:'decorated'}}>Accept terms and conditions</span>
                    </Link>
                </div>
            </div>
            <div className={styles.modalFooter}>
                <div className={styles.contentButtons}>
                  {respondQuestion ?
                  <Fragment>
                  {showAnswerButtons ? (
                    <>
                      <button className={styles.button} disabled={!acceptTerms} onClick={handleAnswer("yes")}>
                        <b className={styles.buttonText}>Yes</b>
                      </button>
                      <button className={styles.button} disabled={!acceptTerms} onClick={handleAnswer("no")}>
                        <b className={styles.buttonText}>No</b>
                      </button>
                    </>
                  ) : (
                    <button className={styles.button} onClick={handleDownload}>
                      <b className={styles.buttonDownloadText}>Download and Continue</b>
                    </button>
                  )}
                  </Fragment>
                  :
                  <button className={styles.button} disabled={!acceptTerms} onClick={handleContinue}>
                    <b className={styles.buttonText}>Continue</b>
                  </button>
                  }
                </div>
                <div className={styles.goBack} onClick={onClose}>Cancel</div>
            </div>
            </div>
    );
};


MakePublic.propTypes = {
  onClose: PropTypes.func,
  setFormQuestion: PropTypes.func,
  prediction: PropTypes.any,
  buyCrypto: PropTypes.func,
  buyCreditCard: PropTypes.func,
  typePay: PropTypes.any,
  showAnswerButtons: PropTypes.any,
  setShowAnswerButtons: PropTypes.func,
  generatePDF: PropTypes.func,
  setOpenAlert: PropTypes.func,
  respondQuestion: PropTypes.any,
  date: PropTypes.any,
  sign: PropTypes.any
};

export default MakePublic;
