import React, { Fragment } from 'react';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
/* imports para carousel */
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const CarouselSign = ({ items, setSelectedShow, selectedShow, loadDataIframe, setLoadDataIframe }) => {

  const responsive = {
    big:{
      breakpoint: { max: 4000, min: 2880},
      items: 2,
      paritialVisibilityGutter: 1
    },
    desktop: {
      breakpoint: { max: 2879, min: 900 },
      items: 2,
      paritialVisibilityGutter:1
    },
    tablet: {
      breakpoint: { max: 899, min: 600 },
      items: 2,
      paritialVisibilityGutter: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      paritialVisibilityGutter: 1
    }
  };
  

  const handleClick = (item) => () => {
    if(!loadDataIframe && selectedShow?.token_id !== item?.token_id ){
      setLoadDataIframe(true);
      setSelectedShow(item);
    }
  };

  const handleGo = (id) => () => {
    const collection = String(window.localStorage.getItem('collection')).toLowerCase();
    const red = process.env.REACT_APP_NETWORK == 5 ? "goerli":"ethereum";
    window.open(`${process.env.REACT_APP_URL_OPENSEA}/es/assets/${red}/${collection}/${id}`, '_blank');
  };  

  return (
      <Box
        component={Carousel}
        ssr={false}
        partialVisible
        responsive={responsive}
        arrows={true}
        showDots={true}
        renderButtonGroupOutside={true}
        sx={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '300px',
          overflow: 'hidden',
          margin: '0 auto',
          ".react-multi-carousel-dot>button":{
            background: 'var(--color-gray)'
          },
          ".react-multi-carousel-dot--active>button":{
            background: 'var(--color-blanchedalmond)'
          },
          '@media screen and (max-width:1000px)':{
            width: '100%'
          }
        }}
      >
        {
          items?.map((item,index)=>{
            return (
              <div key={index}
                style={{
                  width:'100px', 
                  height: '100px',
                }}
              >
                
                  {
                  Number(item) > 0 ?
                  <Fragment>
                    <div 
                      style={{
                        position:'relative',
                        width:'80px', 
                        height: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background:'rgba(11, 19, 34,.8)',
                        cursor: 'pointer',
                        borderRadius: '4px 4px'
                      }}
                    >
                      <img alt={`pos-${index}`} 
                        style={{ 
                          position:'absolute',
                          width:'35px', 
                          height: '35px',
                          top: '5px'
                        }} 
                        src="/OpenSeaIco.svg" 
                      />
                      <div
                        onClick={handleGo(item)} 
                        style={{ 
                          position: 'absolute',
                          width:'100%',
                          height:'100%',
                          color:'var(--color-blanchedalmond)',
                          bottom: '-20px', 
                          left: '0px', 
                          display:'flex',
                          justifyContent:'center',
                          cursor:'pointer',
                          alignItems:'center',
                          flexDirection:'column',
                          fontSize:'0.8rem'
                        }}>
                        <b>{item}</b>
                      </div>
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    <div 
                      style={{
                        position:'relative',
                        width:'80px', 
                        height: '80px',
                        boxShadow:selectedShow?.token_id==item?.token_id && 'inset 0px 0px 0px 2px var(--color-blanchedalmond)', 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background:'rgba(11, 19, 34,.8)',
                        cursor: 'pointer',
                        borderRadius: '4px 4px'
                      }}
                    >
                      {
                        item && item?.metadata && item?.metadata.json_data && 
                        item?.metadata?.json_data?.isVideo && item?.image_url &&
                        <video 
                        style={{ border:selectedShow?.token_id==item?.token_id && '3px solid var(--color-blanchedalmond)', cursor: 'pointer', width:'90px', height: '90px' }} alt="MyNFT" controlsList="nodownload noremoteplayback" controls={false} autoPlay={true} loop={true} muted={true}>
                            <source src={item.image_url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                      }
                      {
                        item && item?.metadata && item?.metadata.json_data && 
                        !(item?.metadata?.json_data?.isVideo) && item?.thumb_url &&
                        <Fragment>
                          <img alt={`pos-${index}`} 
                            style={{ 
                              position:'absolute',
                              width:'50px', 
                              height: '50px',
                              top: '-7px'
                            }} 
                            src="/Cuernos.png" 
                          />
                          <div
                            onClick={handleClick(item)} 
                            style={{ 
                              position: 'absolute',
                              width:'100%',
                              height:'100%',
                              color:'var(--color-blanchedalmond)',
                              bottom: '-20px', 
                              left: '0px', 
                              display:'flex',
                              justifyContent:'center',
                              cursor:'pointer',
                              alignItems:'center',
                              flexDirection:'column',
                              fontSize:'0.8rem'
                            }}>
                            <b>{item?.token_id}</b>
                            <b>{item?.metadata.json_data?.attributes?.map((attr)=> attr?.value && attr?.trait_type == "Sign" && attr?.value )}</b>
                          </div>
                        </Fragment>
                      }
                    </div>
                  </Fragment>
                  }
              </div>
            )
          })
        }
      </Box>
  );
};

CarouselSign.propTypes = {
  items: PropTypes.array,
  setSelectedShow: PropTypes.func,
  selectedShow: PropTypes.any,
  loadDataIframe: PropTypes.bool,
  setLoadDataIframe: PropTypes.func
};

export default CarouselSign;