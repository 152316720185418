import { useContext } from 'react';
import styles from "./SectionAbout.module.css";
import PropTypes from 'prop-types';
import { LayerContent } from 'Layout/Main';
import { SanityContext } from 'context/SanityContext';
import BlockContentRenderer from 'components/Block/BlockContentRenderer';

const SectionAbout = ({ setArticleRef }) => {

    const { itemsAbout } = useContext(SanityContext);
    
    return (
        <LayerContent>
            <article id="section1" className={`${styles.article}`} ref={(ref) => setArticleRef(1, ref)}>
                <div className={styles.conte}>
                    <BlockContentRenderer blocks={itemsAbout?.title} className={styles.title} />
                    <div className={styles.contentBody}>
                        <BlockContentRenderer blocks={itemsAbout?.description} align={itemsAbout?.alignText} className={styles.loremIpsumDolorContainer1}  />
                    </div>
                </div>
            </article>
            <article id="section2" className={`${styles.article}`} ref={(ref) => setArticleRef(2, ref)}>
                <div className={styles.conte}>
                    <div className={styles.contentBody} style={{margin:'0 auto',maxWidth:'500px'}}>
                        <BlockContentRenderer blocks={itemsAbout?.description2} align={itemsAbout?.alignText2} className={styles.loremIpsumDolorContainer2}  />
                    </div>
                </div>
            </article>
        </LayerContent>
    );
};

SectionAbout.propTypes = {
    setArticleRef: PropTypes.any
};

export default SectionAbout;