
import { createContext,useState } from "react";
import PropTypes from "prop-types";

export const FormBuyContext = createContext();

const FormBuyProvider = ({ children }) => {
    
    const [ formDataBuy, setFormDataBuy ] = useState(null);

    const resetForm = () => {
      setFormDataBuy(null)
    }

    const handleChange = (value, name) => {
        if (String(name).toUpperCase().includes("ANSWER")) {
          setFormDataBuy((prevData) => {
            const updatedAnswers = [...(prevData.answers || [])];
            const index = Number(name.slice(6));
            updatedAnswers[index] = value;
            return { ...prevData, answers: updatedAnswers };
          });
        } else {
          setFormDataBuy((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
    };

    return (
        <FormBuyContext.Provider value={{formDataBuy,handleChange,resetForm}}>
            {children}
        </FormBuyContext.Provider>
    );
}

FormBuyProvider.propTypes = {
    children: PropTypes.node
}

export default FormBuyProvider;
