import { useCallback, Fragment, useContext, useState, useEffect } from "react";
import styles from "./Header.module.css";
import Wallet from "components/Wallet/Wallet";
import { UserContext } from "context/UserContext";
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, useLocation  } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { PendingTxContext } from "context/PendingTxContext";
import MenuIcon from '@mui/icons-material/Menu';
import ListMenu from "./components/ListMenu";
import { SectionActiveContext } from "context/SectionActiveContext";
import { SanityContext } from "context/SanityContext";
import { convertToETH, getPriceNFT } from "services/blockchain";

const Header = () => {

    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
	const { user, open, setOpen } = useContext(UserContext);
    const { sectionActive } = useContext(SectionActiveContext);
    const { itemsTitles } = useContext(SanityContext)
    const { isPending } = useContext(PendingTxContext);
    const [ newNFT, setNewNFT ] = useState(false);
    const [ openMenu, setOpenMenu ] = useState(false);
    const [ showListMenu, setShowListMenu ] = useState(false);
    const [ priceNFT, setPriceNFT ] = useState(null);

    const openWallet = useCallback(()=>{
        setOpen(true);
    },[open]);


    const openDrawerMenu = useCallback(()=>{
        setOpenMenu(true);
    },[openMenu]);

    const go = useCallback((route)=>  {
        navigate(`/${route}`);
    },[navigate]);

    const handleScrollTop = () => {
        go("")
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(()=>{
        if(!isPending){
            const isNew = Boolean(JSON.parse(window.localStorage.getItem("newNFT")));
            setNewNFT(isNew);
        }else{
            setNewNFT(null);
        }
    },[isPending])

    useEffect(() => {
        if (String(pathname).toUpperCase() === '/PROFILE') {
          window.localStorage.removeItem('newNFT');
          setNewNFT(null);
          setShowListMenu(false);
        }else{
            setShowListMenu(true)
        }
    }, [pathname]);

    useEffect(() => {
        if (sectionActive == 0) {
            window.scrollTo(0, 0);
        }
    }, [sectionActive]);

    const getDataPrice = async () => {
        try {
          const newPrice = await getPriceNFT();
          if (newPrice) {
            return await convertToETH(newPrice, null, process.env.REACT_APP_RPC);
          } else {
            return null;
          }
        } catch (e) {
          console.log("getDataPrice:", e);
          return null; 
        }
    };

    useEffect(() => {
        (async () => {
          const price = await getDataPrice();
          console.log("debug useEffect price:",price);
          setPriceNFT(price);
        })();
    }, []);

  
    return (
        <Fragment>
            <header className={styles.header} >
                <div className={styles.contentH}>
                <div className={styles.navBar}>
                    <img onClick={handleScrollTop} className={styles.image14Icon} alt="" src="https://images.persea.app/extra_files/docena/compress/Cuernos.png" />
                    <div style={{ flex:1}}/>
                    <div className={styles.vibratingText}>
                        {priceNFT && <b>Price {priceNFT+''} ETH</b>}
                    </div>
                    <div className={styles.walletParent}>
                        {isPending && <div style={{display:'flex',alignItems:'center',gap:'5px'}}><CircularProgress size="18px" sx={{ color: 'var(--color-blanchedalmond)' }}/> <span><small>Pending transaction...</small></span></div> }
                        {user && user?.wallet && 
                        <div style={{position:'relative'}}>
                            {newNFT &&
                            <small style={{position:'absolute',left:'-25px', top:'-5px',color:"#fff",padding:'2px',background:'red', borderRadius:' 5px 5px 0px 5px'}}>
                                New
                            </small>
                            }
                            <PersonIcon onClick={()=>go("profile")} sx={{ fontSize: '30px', cursor: 'pointer' }}/>
                        </div>}
                        <img
                            className={styles.walletIcon}
                            alt=""
                            src="/wallet.svg"
                            onClick={openWallet}
                        />
                        {
                        showListMenu && itemsTitles?.length > 0 &&
                        <Fragment>
                            <MenuIcon 
                            sx={{ 
                                fontSize: '30px',
                                cursor: 'pointer'
                            }} 
                            onClick={openDrawerMenu}/>
                        </Fragment>
                        }
                    </div>
                </div>
                </div>
            </header>
            <div className={styles.spaceByHeader} />
            <ListMenu
                openDrawer={openMenu}
                setOpenDrawer={setOpenMenu}
                sectionActive={sectionActive}
                itemsTitles={itemsTitles}
            />
            <Wallet
                openDrawer={open}
                setOpenDrawer={setOpen}
            />

        </Fragment>
    );
};



export default Header;