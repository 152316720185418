import { useContext, useEffect, useState } from "react";
import styles from "./HomeHero.module.css"
import MathMotionCarousel3D from "components/MathMotionCarousel3D/MathMotionCarousel3D";
import PropTypes from 'prop-types';
import ButtonScroll from "components/ButtonScroll/ButtonScroll";
import SocialsIcons from "components/SocialsIcons/SocialsIcons";
import { SanityContext } from "context/SanityContext";

const handleResize = (size) => {
    if(size < 600){
        return "mobile";
    }else
    if(size >= 600 && size < 900){
        return "tablet";
    }else
    if(size >= 900 && size < 1440){
        return "desktop-mdpi"
    }else
    if(size >= 1140 && size < 2879){
        return "desktop"
    }else
    if(size >= 2880){
        return "big";
    }
}


const HomeHero = ({ setArticleRef, handleGoSection }) => {

    const { itemsFooter } = useContext(SanityContext);
    let width = window.innerWidth;
    const [ typeDevice, setTypeDevice] = useState(handleResize(width));
    const [ radio, setRadio ] = useState(0);

    const handleButtonScroll = () => {
        handleGoSection(1);
    }

    useEffect(() => {
        window.addEventListener("resize", function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
    
        window.removeEventListener("resize",function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
        
    }, [width]);

    useEffect(() => {
        if(typeDevice === "mobile" || typeDevice === "tablet"){
            width = window.innerWidth;
            setRadio((width - (width * 0.18)));
        } else {
            width = window.innerWidth;
            if(width > 1900 && width < 2400){
                setRadio((width - (width * 0.50)));
            } else if (width >= 2400){
                setRadio(1000);
            }
            else{
                setRadio((width - (width * 0.30)));
            }
        }
    },[typeDevice]);


    return (
        <article id="section0" className={`${styles.content}` }  ref={(ref) => setArticleRef(0, ref)} style={{maxWidth: '1900px', margin:'auto'}}>
            <div className={styles.hero}>
                <img
                    className={styles.afd5b86E1634315B1d605ff4c9Icon}
                    alt=""
                    src="https://images.persea.app/extra_files/docena/compress/LogoHomeHero.png"
                />
                <b className={styles.hereIsAn}>
                    12 DESIGNS, 12 MONTHS, 1 NFT
                </b>
            </div>
            <div className={styles.ilustraciones}>
                <MathMotionCarousel3D radius={radio} speed="0.019" typeDevice={typeDevice}/>
            </div>
            <ButtonScroll handleFunction={handleButtonScroll}/>
            <div className={styles.socialsLeft}>
                <SocialsIcons icons={itemsFooter?.left||[]}/>
            </div>
        </article>
    );
};


HomeHero.propTypes = {
    setArticleRef: PropTypes.any,
    handleGoSection: PropTypes.func
};

export default HomeHero;