import PropTypes from "prop-types";
import { createContext, useState, useEffect } from "react"
import { 
    footer, 
    header, 
    section1, 
    zodiacDescription,
    customization,
    quiz,
    faqs,
    firstData,
    questionByPromp,
    termsAndCondition,
    modalShare,
    sharingData,
    sectionPayment,
    sharingWithChatGPT,
    aboutTheDrop,
    listOfTitles,
    team
} from "api/sanity"

export const SanityContext = createContext();

const SanityProvider = ({ children }) => {
    
    const [ itemsFooter, setItemsFooter ] = useState(null);
    const [ itemsHeader, setItemsHeader ] = useState(null);
    const [ itemsAbout, setItemsAbout ] = useState(null);
    const [ itemsPreviewSing, setItemsPreviewSing ] = useState(null);
    const [ itemsPayment, setItemsPayment ] = useState(null);
    const [ itemsCustomization, setItemsCustomization ] = useState(null);
    const [ firstItem, setFirstData ] = useState(null);
    const [ itemsQuiz, setItemsQuiz ] = useState(null);
    const [ itemsFAQs, setItemsFAQs ] = useState(null);
    const [ questionPromp, setQuestionPromp ] = useState(null);
    const [ termsConditions, setTermsConditions] = useState(null);
    const [ modalShareText, setModalShareText] = useState(null);
    const [ itemsSharing, setItemsSharing ] = useState(null);
    const [ itemsGPTSharing, setItemsGPTSharing ] = useState(null);
    const [ itemsAboutDrop, setItemsAboutDrop ] = useState(null);
    const [ itemsTitles, setItemsTitles ] = useState(null);
    const [ itemsTeam, setItemsTeam ] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    value1, 
                    value2, 
                    value3, 
                    value4, 
                    value5, 
                    value6, 
                    value7, 
                    value8, 
                    value9, 
                    value10, 
                    value11, 
                    value12, 
                    value13, 
                    value14, 
                    value15, 
                    value16, 
                    value17
                ] = await Promise.all([
                    footer(),
                    header(),
                    section1(),
                    zodiacDescription(),
                    sectionPayment(),
                    customization(),
                    quiz(),
                    faqs(),
                    firstData(),
                    questionByPromp(),
                    termsAndCondition(),
                    modalShare(),
                    sharingData(),
                    sharingWithChatGPT(),
                    aboutTheDrop(),
                    listOfTitles(),
                    team()
                ]);

                setItemsFooter(value1);
                setItemsHeader(value2);
                setItemsAbout(value3);
                setItemsPreviewSing(value4);
                setItemsPayment(value5);
                setItemsCustomization(value6);
                setItemsQuiz(value7);
                setItemsFAQs(value8);
                setFirstData(value9);
                setQuestionPromp(value10);
                setTermsConditions(value11);
                setModalShareText(value12)
                setItemsSharing(value13);
                setItemsGPTSharing(value14);
                setItemsAboutDrop(value15);
                setItemsTitles(value16);
                setItemsTeam(value17)
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const contextValue = {
        itemsFooter,
        itemsHeader,
        itemsAbout,
        itemsPreviewSing,
        itemsPayment,
        itemsCustomization,
        itemsQuiz,
        itemsFAQs,
        firstItem,
        questionPromp,
        termsConditions,
        modalShareText,
        itemsSharing,
        itemsGPTSharing,
        itemsAboutDrop,
        itemsTitles,
        itemsTeam
    };

    return (
        <SanityContext.Provider value={contextValue}>
            {children}
        </SanityContext.Provider>
    );
}

SanityProvider.propTypes = {
    children: PropTypes.node
}

export default SanityProvider;
