import { useContext, useEffect, Fragment } from "react";
import styles from "./SectionCustomization.module.css";
import PropTypes from "prop-types";
import { LayerContent } from "Layout/Main";
import { SanityContext } from "context/SanityContext";
import { FormBuyContext } from "context/FormBuyContext";
import Calendar from "components/Calendar/Calendar";
import BlockContentRenderer from "components/Block/BlockContentRenderer";
import { useCurrentSign } from "hooks/selectSign";
import { PendingTxContext } from "context/PendingTxContext";
import CircularProgress from "@mui/material/CircularProgress";
import { UserContext } from "context/UserContext";

const SectionCustomization = ({ setArticleRef, handleGoSection }) => {
    
    const { user } = useContext(UserContext);
    const { getSignByDate } = useCurrentSign();
    const { itemsCustomization, itemsPayment } = useContext(SanityContext);
    const { formDataBuy, handleChange } = useContext(FormBuyContext);
    const { isPending } = useContext(PendingTxContext);

    const changeDate = (val) => {
        handleChange(val,"date")
        let timeout = setTimeout(()=>{
            handleGoSection(6);
            clearTimeout(timeout);
            return null;
        },300);
    }

    useEffect(()=>{
        if(formDataBuy?.date){
            const { name } = getSignByDate(formDataBuy?.date);
            if(name){
                handleChange(name,"sign")
            }
        }
    },[formDataBuy?.date])

    let isClickAnother = window.localStorage.getItem("buy-another");
    useEffect(() => {
      isClickAnother = window.localStorage.getItem("buy-another");
      if(user?.wallet && String(isClickAnother).toUpperCase() === "TRUE"){
        window.localStorage.setItem("buy-another",false);
        handleGoSection(5);
      }
    }, [user]);

    return (
        <LayerContent>
            <article id="section5" className={`${styles.article}`} ref={(ref) => setArticleRef(5, ref)}>
                <div className={styles.conte}>
                    <BlockContentRenderer blocks={itemsCustomization?.description} className={styles.title} />
                    <div className={styles.contentImg}>
                       <img className={styles.handsImg} alt="hands" width="100%" height="100%" src={"https://images.persea.app/extra_files/docena/hands.gif"}/>
                    </div>
                    {isPending ? <div style={{ margin:'0 auto', display:'flex',alignItems:'center',gap:'5px'}}><CircularProgress size="18px" sx={{ color: 'var(--color-blanchedalmond)' }}/> <span><small>Pending transaction...</small></span></div> 
                    :
                    <Fragment>
                        <div style={{ margin:'0 auto', position: 'relative', top: '-42px' }}>
                            <Calendar onFunction={changeDate} />
                        </div>
                        <div style={{ margin:'0 auto', position: 'relative', top: '-42px' }}>
                            <BlockContentRenderer blocks={itemsPayment?.textOfCalendar?.text} className={styles.textBrand} />
                        </div>
                    </Fragment>
                    }
                </div>
            </article>
        </LayerContent>
    );
};

SectionCustomization.propTypes = {
    setArticleRef: PropTypes.any,
    handleGoSection: PropTypes.func
};

export default SectionCustomization;