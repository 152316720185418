import axios from "axios";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const URL = `${process.env.REACT_APP_API}/prompt?domain=${process.env.REACT_APP_DOMAIN}`;
const KEY = process.env.REACT_APP_X_API_KEY;

export const getPrompt = async (text = '') => {

    if (text === '') {
        return Promise.reject('');
    }
    try {
        const body = {
            prompt: text,
            is_public : false
        };

        const response = await axios.post(URL, body);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};


export const savePrompt = async (text = '') => {

    if (text === '') {
        return Promise.reject('');
    }
    try {
        const body = {
            prompt: text,
            is_public : true
        };

        const response = await fetch(URL, {
            method: 'PUT',
            headers: {
            'X-API-Key': KEY
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            Promise.reject(`Error: ${response.message}`);
        }
        return response.json();
    } catch (error) {
        return Promise.reject(error);
    }
};
