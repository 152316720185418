import mintAndList from 'contracts/PerseaSimpleCollectionSeq.json';
import Web3 from 'web3';
import { handleErrors } from "./messages";
import axios from "axios";
import { getHistoryByWallet } from './history';
import { findAllByDomain } from './collection';

const abis = {
    1 : mintAndList
}
const url = process.env.REACT_APP_API+"blockchain?domain"+process.env.REACT_APP_DOMAIN

export const subscribeMinter = async (provider, rpc=null, contractAddress, minterAddress, contractType = 1) => {
	let web3 = getWebInstance(provider, rpc)
	let contract = new web3.eth.Contract(
			abis[contractType].abi,
			web3.utils.toChecksumAddress(contractAddress)
	);
	minterAddress =  web3.utils.toChecksumAddress(minterAddress)
	let transaction = await contract.methods.subscribe().send({ from: minterAddress }, (error, transaction) => {
			if (error) {
					console.error('An error occurred', error);
					return new Error("Please contact support with the administrator");

			}
			console.log('transaction', transaction);
			return transaction
	});
	return transaction
};

export const checkIfMinter = async (provider, rpc=null, contractAddress, addressToCheck, contractType = 1) => {
	let web3 = getWebInstance(provider, rpc)
	let contract = new web3.eth.Contract(
		abis[contractType].abi,
		web3.utils.toChecksumAddress(contractAddress)
	);
	let isMinter = await contract.methods.isMinter(addressToCheck).call();
	return isMinter
};

export const getWebInstance = (provider, rpc = null) => {
	let web3 = null;
	if (provider) {
			web3 = new Web3(provider);
	} else {
			if(rpc) {
					web3 = new Web3(rpc);
			} else {
					web3 = new Web3(process.env.REACT_APP_RPC);
			}
	}
	return web3
}

export const getIfHaveTxt = async (domain, blockchain,from, gas = 'NONE') => {
  const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=${gas}`
    return axios.get(url, {
      headers: {
          'X-API-Key': process.env.REACT_APP_X_API_KEY
      }
  }).then(res=>res.data)
}
export const validateTx = async(domain, blockchain, from,tx)=>{
  const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=NONE&transaction=${tx}`
  return axios.get(url, {
    headers: {
        'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
}).then(res=>res.data)
}
export const priceWei = async(provider,to, rpc = null,contractType = 1) => {
  let web3 = getWebInstance(provider, rpc)
  let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
  let price = await contract.methods.getPrice().call()
  return price
}

export const getPriceNFT = async () => {
  try{
    const collections = await findAllByDomain();
    const { collection_key } = collections?.length > 0 ? collections[0] : null;
    if(collection_key){
      const resPriceWei = await priceWei(null,collection_key,process.env.REACT_APP_RPC);
      return resPriceWei;
    }else{
      return null;
    }
  } catch (e) {
    console.log(e)
  }
}



const maxRetry = 29;
export const getHistoryTx = async (wallet, tx, retry = 0) => {
  try {
    const history = await getHistoryByWallet(wallet);
    const found = history.find(x => x.transaction_hash.toUpperCase() === tx.toUpperCase());
    console.log("debug search tx:",tx);
    if (found) {
      console.log("debug found tx:",found);
      return found;
    } else if (retry <= maxRetry) {
      return await getHistoryTx(wallet, tx, retry + 1);
    } else {
      console.log("debug tx not found");
      return null;
    }
  } catch (error) {
    console.log("Error:", error);
    return null;
  }
}

export const convertToETH = (amountWei,provider,rpc=null) => {
  const web3 = getWebInstance(provider,rpc)
  const eth = web3.utils.fromWei(String(amountWei).toString(), 'ether');
  return eth;
}

export const getEstimatedGasMagicTotal = async (provider, blockchain, balance, from, to, url, price, contractType = 1, retry = 1) => {
  const maxRetry = 5;
  console.log("debug estimated gas intent: ",retry);
  try{
    if(retry > maxRetry ){
      return null
    }
    
    const transaction = await getIfHaveTxt(process.env.REACT_APP_DOMAIN, blockchain, from, 'FULL');
    if (transaction.gas_info) {
      const gasInfo = transaction.gas_info;
      gasInfo['value'] = await priceWei(provider, to);
      gasInfo['from'] = process.env.REACT_APP_ADDRESS_WITH_ETH;
 
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(
        abis[contractType].abi,
        web3.utils.toChecksumAddress(to)
      );

      const estimatedGas = await contract.methods.payableMint(url).estimateGas(gasInfo);
      const totalGas = estimatedGas * (gasInfo?.gasPrice + gasInfo?.maxPriorityFeePerGas);
      const totalGasToETH = convertToETH(totalGas, provider);
      console.log("debug totalGasToETH: ",totalGasToETH);
      if(!totalGasToETH){
        await getEstimatedGasMagicTotal(provider, blockchain, balance, from, to, url, price, contractType, retry + 1);
      }else{
        return totalGasToETH;
      } 
    }else{
      await getEstimatedGasMagicTotal(provider, blockchain, balance, from, to, url, price, contractType, retry + 1);
    }
  }catch(e){
    console.log("debug error catch ::", e);
    await getEstimatedGasMagicTotal(provider, blockchain, balance, from, to, url, price, contractType,retry + 1);
  }
}



export const payableMint = async (provider,blockchain, from, to, uri, contractType = 1) => {

    try{
    let transaction = await getIfHaveTxt(process.env.REACT_APP_DOMAIN,blockchain,from,'FULL')
    if(transaction.gas_info){
        let gasInfo = transaction.gas_info;
        gasInfo['value'] = await priceWei(provider,to)
        let web3 = new Web3(provider);
        let contract = new web3.eth.Contract(
            abis[contractType].abi,
            web3.utils.toChecksumAddress(to)
        );
        console.log('uri',uri)
        let res = await contract.methods.payableMint(uri).send(gasInfo, async(error, transaction) => {
        console.log('error', error);
        console.log('transaction', transaction);
        if(transaction) {
                await validateTx(process.env.REACT_APP_DOMAIN,blockchain,from,transaction)
                let intervalValidateTransaction = setInterval(async() =>{
                    let validation = await getIfHaveTxt(process.env.REACT_APP_DOMAIN,blockchain,from)
                    if (!validation.pending) {
                        if (validation && validation.receipt && validation.receipt.transaction) {
                            clearInterval(intervalValidateTransaction)
                            return transaction
                        } else {
                            clearInterval(intervalValidateTransaction)
                            if (error.message) {
                              throw new Error(error.message)
                            }
                            throw new Error("Please contact support with the administrator")
                        }
                    }
                },6000) 
        }
        })
        return res; 
    } else {
        let web3 = new Web3(provider);
        let contract = new web3.eth.Contract(
            abis[contractType].abi,
            web3.utils.toChecksumAddress(to)
        );
        let value = await priceWei(provider,to)
        let res = await contract.methods.payableMint(uri).send({
            from : from,
            value : value
        }, (error, transaction) => {
            console.log('error', error);
            console.log('transaction', transaction);
        })
        return res;
    }

    } catch (e) {
      console.log('error:',e);
      const res = handleErrors(e)?.message || "An error has occurred. Please try again later."
      return Promise.reject(res)
    }
};
