


import PropTypes from "prop-types";
import { createContext, useState } from "react"

export const SectionActiveContext = createContext();

const SectionActiveProvider = ({ children }) => {
    
    const [ sectionActive, setSectionActive ] = useState(-1);

    return (
        <SectionActiveContext.Provider value={{ sectionActive, setSectionActive }}>
            {children}
        </SectionActiveContext.Provider>
    );
}

SectionActiveProvider.propTypes = {
    children: PropTypes.node
}

export default SectionActiveProvider;
