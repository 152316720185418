import { Fragment, useEffect, useContext } from "react";
import HomeHero from "./components/HomeHero/HomeHero";
import styles from "./Home.module.css";
import useMovedSection from "hooks/ScrollingSection";
import SectionAbout from "./components/SectionAbout/SectionAbout";
import PreviewSign from "./components/PreviewSign/PreviewSign";
import SectionCustomization from "./components/SectionCustomization/SectionCustomization";
import SectionQuestions from "./components/SectionQuestions/SectionQuestions";
import FAQs from "./components/FAQs/FAQs";
import { Link } from "react-scroll";
import { SanityContext } from "context/SanityContext";
import SectionCompleteActivity from "./components/SectionCompleteActivity/SectionCompleteActivity";
import SectionAboutDrop from "./components/SectionAboutDrop/SectionAboutDrop";
import { SectionActiveContext } from "context/SectionActiveContext";
import SectionTeam from "./components/SectionTeam/SectionTeam";

const Home = () => {
  
  const { itemsHeader } = useContext(SanityContext);
  const { setSectionActive } = useContext(SectionActiveContext);
  const { 
    positionObserver,
    articleActive,
    setArticleActive, 
    setArticleRef,
    handleSmooth
  } = useMovedSection();

  const handleCustomSmooth = (pos) => {
    setArticleActive(pos);
    let timeout = setTimeout(()=>{
      handleSmooth(pos);
      clearTimeout(timeout);
      return null;
    },200);
  };

  const handleGoSection = (pos) => {
    setArticleActive(pos);
  };

  const handleNext = () => {
    setArticleActive(positionObserver+1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSectionActive(positionObserver)
  }, [positionObserver]);
  

  return (
    <Fragment>
      <HomeHero handleGoSection={handleGoSection} setArticleRef={setArticleRef} />
      <SectionAbout setArticleRef={setArticleRef} />
      <SectionAboutDrop setArticleRef={setArticleRef} />
      <PreviewSign setArticleRef={setArticleRef} />
      <SectionCustomization handleGoSection={handleCustomSmooth} setArticleRef={setArticleRef} />
      <SectionQuestions 
        articleActive={articleActive}
        setArticleActive={setArticleActive}
        setArticleRef={setArticleRef}
        handleGoSection={handleCustomSmooth}
      />
      <SectionTeam setArticleRef={setArticleRef} />
      <FAQs setArticleRef={setArticleRef} />
      <SectionCompleteActivity />
      {
        ((positionObserver > 0 && positionObserver < 5) ) &&
        <div className={styles.floatContent}>
          <div className={styles.controls}>
            <div 
              className={styles.startExperience}
            >
              <Link
                activeClass="active"
                to={`section${positionObserver+1}`}
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={handleNext}
              >
               { itemsHeader?.startExperience ? itemsHeader.startExperience:'START YOUR PERSONALIZED EXPERIENCE!'}
              </Link>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default Home;
