import { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { LayerContent } from 'Layout/Main';
import styles from "./SectionCompleteActivity.module.css";
import { SanityContext } from 'context/SanityContext';
import Block from 'components/Block/Block';
import { handleMadeTwitter } from 'utils/twitterLink';

const handleEmail = (email) => {
    const subject = "";
    const body = ``;
    const to = email;
    const mailtoUrl = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
};

const FollowUserByUrl = ({item}) => {

    const handleFollowUser = (link) => () => {
        if(link && link !== ''){
            window.open(link, '_blank');
        }
    }

    return (
    <Fragment>
        {
        (String(item?.label).toUpperCase() === "info@docena.art" ||  item?.url === "https://no") ?
        <div 
            className={styles.item} 
            onClick={()=>handleEmail(item?.label)}
        >
            <div className={styles.iconLeft}>
                <img width="100%" className={styles.imgIcon} alt={item?.label} src={item?.iconLeft} />
            </div>
            <div className={styles.label}>
                {item?.label}
            </div>
            <div className={styles.iconRight}>
                <img width="100%" className={styles.imgIcon} alt={item?.label} src={item?.iconRight}/>
            </div>
        </div>
        :
        <div 
            className={styles.item} 
            onClick={handleFollowUser(item?.url ||'')}
        >
            <div className={styles.iconLeft}>
                <img width="100%" className={styles.imgIcon} alt={item?.label} src={item?.iconLeft} />
            </div>
            <div className={styles.label}>
                {item?.label}
            </div>
            <div className={styles.iconRight}>
                <img width="100%" className={styles.imgIcon} alt={item?.label} src={item?.iconRight}/>
            </div>
        </div>
        }
    </Fragment>
    )
}

const MadeTweetByUrl = ({item}) => {
  
    return (
    <div 
        className={styles.item} 
        onClick={handleMadeTwitter(item?.sharingText||'')}
    >
        <div className={styles.iconLeft}>
            <img alt={item?.label} src={item?.iconLeft}/>
        </div>
        <div className={styles.label}>
            {item?.label}
        </div>
        <div className={styles.iconRight}>
            <img alt={item?.label} src={item?.iconRight}/>
        </div>
    </div>
    )
}


const SectionCompleteActivity = () => {

    const { itemsSharing } = useContext(SanityContext);
   
    return (
        <LayerContent>
            <article  className={`${styles.groupParent}`} >
                <div className={styles.contentOptions}>
                    <h2 className={styles.title}>
                        {itemsSharing?.title}
                    </h2>
                    <Block blocks={itemsSharing?.description} styleClass={styles.loremIpsumDolorContainer1} />
                    <div className={styles.contentSteps}>
                        {
                        itemsSharing?.items?.map(( item,index )=>{
                            return (
                                <Fragment key={index} >
                                    {
                                    item && 
                                    <Fragment>
                                        {!item.isShareText &&
                                        <FollowUserByUrl item={item}/>
                                        }
                                    </Fragment>
                                    }
                                </Fragment>
                            )
                        })
                        }
                    </div>
                </div>
            </article>
        </LayerContent>
    );
};

FollowUserByUrl.propTypes = {
    item: PropTypes.object
};

MadeTweetByUrl.propTypes = {
    item: PropTypes.object
};

SectionCompleteActivity.propTypes = {
    setArticleRef: PropTypes.func
};

export default SectionCompleteActivity;