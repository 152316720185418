import { useState } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StepBar = ({ activeStep, steps }) => {
    
    const [ collapse, setCollapse ] = useState(false);

    const handleCollapse = () => {
        setCollapse(!collapse);
    }

    return (
        <Box sx={{width:'100%', mb: '2rem'}}>
            <Stepper activeStep={activeStep} alternativeLabel 
                sx={{
                    "&,span.MuiStepLabel-label":{
                        color:'var(--color-blanchedalmond) !important',
                        fontWeight: '500 !important'
                    },
                    "&,.Mui-completed,.Mui-active":{
                        color:'var(--color-red-1) !important'
                    },
                    "&,.Mui-disabled":{
                        color: '#fff !important',
                        opacity: 0.9
                    },
                    "@media screen and (max-width: 600px)":{
                        "&,span":{
                            "span.MuiStepLabel-labelContainer":{
                                display: 'none'
                            }
                        }
                    }
                }}>
                {steps?.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ width: '100%',textAlign: 'left', mt: '1rem',gap: '0.6rem', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column',
                
                color:'var(--color-blanchedalmond)',
                "div.complete":{
                    "&,span.MuiStepLabel-label":{
                        color: '#fff',
                        fontWeight: '700 !important',
                        opacity: '0.8'
                    }
                    
                },
                "div.active":{
                    "&,span.MuiStepLabel-label":{
                        color:'var(--color-red-1)',
                        fontWeight: '700 !important',
                        opacity: '1'
                    }
                },
                "div.disabled":{
                    "&,span.MuiStepLabel-label":{
                        color:'var(--color-blanchedalmond)',
                        fontWeight: '700 !important',
                        opacity: '1'
                    }
                },
                "@media screen and (min-width: 599px)":{
                    display: 'none'
                }
            }}>
                <Box onClick={handleCollapse} sx={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {steps?.length > 0 && steps[activeStep] && steps[activeStep] || ''}
                    { collapse ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                </Box>
                <Collapse in={collapse} timeout="auto" unmountOnExit sx={{boxShadow:'0px 0px 0px 1px var(--color-blanchedalmond)'}} >
                    {steps?.map((label,index) => (
                        <Step key={label} className={
                        `${ 
                            activeStep < index && "complete" || 
                            activeStep == index && "active" || 
                            activeStep > index && "disabled"
                        }`
                        }>
                            <StepLabel>{index+1} {label}</StepLabel>
                        </Step>
                    ))}
                </Collapse>
            </Box>
        </Box>
    );
};

const stepsDefault = [
    'Step 1',
    'Step 2',
    'Step 3'
];

StepBar.defaultProps = {
    activeStep: 1,
    steps: stepsDefault
}

StepBar.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.array
};

export default StepBar;