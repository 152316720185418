import { useContext } from "react";
import styles from "./Footer.module.css"
import SocialsIcons from "components/SocialsIcons/SocialsIcons";
import { SanityContext } from "context/SanityContext";
import { Link } from "react-router-dom"

const Footer = () => {

    const { itemsFooter } = useContext(SanityContext);
    
    return (
        <div className={styles.footer}>
            <div className={styles.footerChild} />
            <footer className={styles.container}>
                <div className={styles.item1}>
                    <SocialsIcons icons={itemsFooter?.left||[]}/>
                </div>
                <div className={styles.item2}>
                    {itemsFooter?.copyright}
                </div>
                <div className={styles.item3}>
                    <div className={styles.iconRight}>
                        {
                        itemsFooter?.right?.map((item,index)=>
                        <div key={index}>
                            {
                            item?.url && item?.isExternal ?
                            <Link to={item.url} target="_blank" rel="noopener noreferrer">
                                <img className={styles.icon} alt={item?.name} src={item?.image} />
                            </Link>
                            :
                            <img
                                className={styles.icon}
                                alt={item?.name} 
                                src={item?.image}
                            />
                            }
                        </div>
                        )
                        }
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;