import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

const styleListItems = {
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily: 'var(--font-open-sans-bold)',
    cursor: 'pointer',
    '&:hover':{
        color: 'var(--color-red-1)',
    }
}

const styleListItems2 = {
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    color: 'var(--color-blanchedalmond)',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily: 'var(--font-open-sans-bold)',
    cursor: 'pointer',
    '&:hover':{
        color: 'var(--color-red-1)',
    }
}

const ListMenu = ({ openDrawer, setOpenDrawer, sectionActive, itemsTitles }) => {

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
            disableScrollLock={true}
            disableBackdropTransition={false}
            sx={{
                "& .MuiDrawer-paper":{
                    top: '0px',
                    height: 'calc(100vh - 0px)',
                    boxShadow: 'none',
                    background: 'linear-gradient(90deg, transparent 0%, transparent 50%, rgba(240, 226, 194,.22) 100%)',
                    // webkitBoxShadow: '22px 0px 22px 5px rgba(240, 226, 194,.3)',
                    // mozBoxShadow: '22px 0px 22px 5px rgba(240, 226, 194,.3)',
                    // boxShadow: '22px 0px 22px 5px rgba(240, 226, 194,.3)',
                    "@media screen and (max-width:600px)":{
                        boxShadow: 'none'
                    }
                },
                "& .MuiModal-backdrop":{
                    boxShadow: 'none',
                    background:'transparent'
                },
                "@media screen and (max-width:600px)":{
                    "& .MuiDrawer-paper":{
                        top: '0px',
                        height: 'calc(100vh - 0px)',
                        background: 'rgba(0,0,0,.88)',
                        "@media screen and (max-width:600px)":{
                            boxShadow: 'none'
                        }
                    }
                }
                // "& .MuiDrawer-paper":{
                //     top: '80px',
                //     width: 'auto',
                //     background: 'rgba(0,0,0,.9)',
                //     height: 'calc(100vh - 80px)',
                //     zIndex:'50'
                // },
                // "& .MuiModal-backdrop":{
                //     background:'transparent',
                //     zIndex:'2'
                // },
                // "&.MuiDrawer-modal":{
                //     zIndex:'0'
                // },
                // "@media screen and (max-width:600px)":{
                //     "& .MuiDrawer-paper":{
                //         top: '0px',
                //         height: 'calc(100vh - 0px)',
                //     },
                //     "&.MuiDrawer-modal":{
                //         zIndex:'100'
                //     }
                // }
            }}
        >
            <Box
                sx={{
                    width:{ xs:'100%',sm:'290px'},
                    "@media screen and (max-width:600px)":{
                        width:'calc(100% - 40px)',
                        margin: '0 auto'
                    }
                }}
            >
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        color: '#fff'
                    }}
                >
                    <ListItem 
                        sx={{
                            width: '100%',
                            height: '100px'
                        }}
                    />
                    {
                        itemsTitles?.map((item,index)=>{
                            return (
                                index <= 5 ?
                                <ListItem 
                                    key={index}
                                    component={Link}
                                    sx={sectionActive >= index  ? styleListItems2: styleListItems}
                                    activeClass="active"
                                    to={index <= 5 ? `section${index}`:`item${index}`}
                                    spy={true}
                                    smooth={true}
                                    offset={-10}
                                    duration={500}
                                    onClick={()=>{
                                        setOpenDrawer(false)
                                    }}
                                >
                                    <div 
                                        style={{
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'flex-start', 
                                            gap:'1rem',
                                            alignItems:'center'
                                        }}
                                    >
                                        <div style={{width:'auto'}}>
                                            {index < 10 ? `0${(index+1)}` : (index+1) }
                                        </div>
                                        <div style={{ width:'auto', padding:'0px 1rem', borderLeft: '2px solid var(--color-blanchedalmond)' }}>
                                            {item}
                                        </div>
                                    </div>
                                </ListItem>:
                                  <ListItem 
                                  key={index}
                                  component={Link}
                                  sx={sectionActive >= (14 + index)  ? styleListItems2: styleListItems} //14 because team start in 20 position
                                  activeClass="active"
                                  to={index <= 5 ? `section${index}`:`item${index}`}
                                  spy={true}
                                  smooth={true}
                                  offset={-20}
                                  duration={500}
                                  onClick={()=>{
                                      setOpenDrawer(false)
                                  }}
                              >
                                  <div 
                                      style={{
                                          width:'100%',
                                          display:'flex',
                                          justifyContent:'flex-start', 
                                          gap:'1rem',
                                          alignItems:'center'
                                      }}
                                  >
                                      <div style={{width:'auto'}}>
                                          {index < 10 ? `0${(index+1)}` : (index+1) }
                                      </div>
                                      <div style={{ width:'auto', padding:'0px 1rem', borderLeft: '2px solid var(--color-blanchedalmond)' }}>
                                        {item}
                                      </div>
                                  </div>
                              </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
        </SwipeableDrawer>
    );
};


ListMenu.propTypes = {
    openDrawer: PropTypes.bool,
    setOpenDrawer: PropTypes.func,
    sectionActive: PropTypes.number,
    itemsTitles: PropTypes.array
};

export default ListMenu;