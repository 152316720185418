import { useState } from "react";
import styles from "./FAQItem.module.css";
import Collapse from "@mui/material/Collapse";
import TextBreak from "components/Text/TextBreak";

const styleCollapse = {
  padding: '0px 1rem',
  "&,p":{
    margin: 'none',
    padding: 'none'
  }
}

const FAQItem = ({ question, answer }) => {
  const [ open, setOpen ] = useState(false);

  const handleCollapse = () => {
    setOpen(!open);
  }
  return (
    <div className={styles.itemFAQ}>
      <div onClick={handleCollapse} className={styles.ques}>
        <div className={styles.question}><b>{question}</b></div>
        <div>
          <img className={`${styles.vectorIcon} ${open && styles.arrowUp}`} alt="" src="/vector11.svg" />
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit sx={styleCollapse}>
        <div>
          <TextBreak text={answer} className={styles.paragraph}/>
        </div>
      </Collapse>
    </div>
  );
};

export default FAQItem;
