import { EthereumProvider } from '@walletconnect/ethereum-provider'
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { web3Errors } from './messages';

const web3Connection = async (provider) => {
    try {
      const web3 = new Web3(provider);
      const chainID = await web3.eth.getChainId();
      console.log("debug chainID::",chainID);
      console.log("debug REACT_APP_CHAIN_ID::",process.env.REACT_APP_CHAIN_ID);
      const network = '';
      console.log("debug network::",network);
      const accounts = await web3.eth.getAccounts();
      const wallet = accounts[0];
  
      if (chainID && chainID !== process.env.REACT_APP_CHAIN_ID) {
        await changeNetwork(provider, process.env.REACT_APP_CHAIN_ID);
      }
  
      return {
        provider,
        chainID,
        network,
        wallet,
      };
    } catch (error) {
        console.log("debug web3Connection::",error);
        const errorCode = String(error?.code) || null;
        if (errorCode !== null) {
            const res = web3Errors[errorCode]?.message || "An error has occurred. Please try again later."
            return Promise.reject(res);
        } else { 
            return Promise.reject("An error has occurred. Please try again later.");
        }
    }
  };
  

export const loginWalletConnect = async (setLoadingWalletConnect) => {
    try {

      const provider = await EthereumProvider.init({
        projectId: process.env.REACT_APP_ID_PROYECT_WALLETCONNECT, 
        chains: [`${process.env.REACT_APP_NETWORK}`],
        showQrModal: true 
      })
    
      await provider.enable();
    
      return new Promise((resolve, reject) => {
        web3Connection(provider)
          .then((user) => {
            localStorage.setItem('walletConnect', true)
            localStorage.setItem('isMetamask', false)
            resolve(user);
          })
          .catch((error) => {
            setLoadingWalletConnect(false)
            localStorage.setItem('walletConnect', false)
            console.error('walletConnect error:', error);
            reject(error);
          });

        provider.on('disconnect', (code, reason) => {
            console.log(code, reason);
            localStorage.removeItem('walletConnect');
            window.location.reload();
        });
      });
    } catch (error) {
      setLoadingWalletConnect(false)
      localStorage.setItem('walletConnect', false)
      const message = String(error).replace(/^Error: /, '');
      return Promise.reject(message);
    }
   
  };
  export const loginMetamask = async (setLoadingMetamask, setUser) => {
    let provider = null;
    try {
      provider = await detectEthereumProvider();
    } catch (error) {
      console.log('error in provider:', error);
      return Promise.reject('Please install a browser wallet.');
    }
    try{
        if (provider) {
        await provider.request({ method: 'eth_requestAccounts' });
        
        return new Promise((resolve, reject) => {
            if (typeof window.ethereum === 'undefined') {
            reject(new Error('Please install a browser wallet and reload web site'));
            }
    
            web3Connection(provider)
            .then((user) => {
              localStorage.setItem('walletConnect', false)
              localStorage.setItem('isMetamask', true)
              resolve(user);
            })
            .catch((err) => {
              localStorage.setItem('isMetamask', false)
              setLoadingMetamask(false)
              reject(err);
            });


            provider.on('accountsChanged', async() => {
                (web3Connection(provider).then(x=>{
                    if(x === undefined){
                        setLoadingMetamask(false)
                        localStorage.setItem('isMetamask', false)
                    }else{
                        setUser(x);
                    }
                    setLoadingMetamask(false)
                }).catch(()=>{
                    setLoadingMetamask(false)
                })
                )
            });
            provider.removeListener('accountsChanged', () => {
                window.localStorage.removeItem('totalNFT');
                window.localStorage.removeItem('answer');
                window.localStorage.removeItem('folder');
                (web3Connection(provider).then(x=>{
                    if(x === undefined){
                        setLoadingMetamask(false)
                        localStorage.setItem('isMetamask', false)
                    }else{
                        setUser(x);
                    }
                    setLoadingMetamask(false)
                }).catch(()=>{
                    setLoadingMetamask(false)
                })
                )
            });

            
            provider.on('chainChanged', () => {
                (web3Connection(provider).then(x=>{
                    if(x === undefined){
                        setLoadingMetamask(false)
                        localStorage.setItem('isMetamask', false)
                    }else{
                        setUser(x);
                    }
                    setLoadingMetamask(false)
                }))
            });
            
            provider.removeListener('chainChanged', () => {
                (web3Connection(provider).then(x=>{
                    if(x === undefined){
                        setLoadingMetamask(false)
                        localStorage.setItem('isMetamask', false)
                    }else{
                        setUser(x);
                    }
                    setLoadingMetamask(false)
                }))
            });

          });
        } else {
            setLoadingMetamask(false)
            localStorage.setItem('isMetamask', false)
            return Promise.reject('Please install a browser wallet and reload web site');
        }
    } catch (err) {
      console.log("debug err::",err);
        const errorCode = String(err?.code) || null;
      
        setLoadingMetamask(false)
        localStorage.setItem('isMetamask', false)
        if (errorCode !== null) {
            console.log("debug errorCode::",err);
            const res = web3Errors[errorCode]?.message || "An error has occurred. Please try again later."
            return Promise.reject(res);
        } else { 
            return Promise.reject("An error has occurred. Please try again later.");
        }
    }
  };

export  const changeNetwork = async (provider,chainId) => {
    return provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `${chainId}` }],
    })
}
