
import { useContext, Fragment } from 'react';
import styles from "./SectionAboutDrop.module.css";
import PropTypes from 'prop-types';
import { LayerContent } from 'Layout/Main';
import { SanityContext } from 'context/SanityContext';
import BlockContentRenderer from 'components/Block/BlockContentRenderer';

const SectionAboutDrop = ({ setArticleRef }) => {

    const { itemsAboutDrop } = useContext(SanityContext);
    
    return (
        <LayerContent>
            <article id="section3" className={`${styles.article}`} ref={(ref) => setArticleRef(3, ref)}>
                <div className={styles.conte}>
                    <BlockContentRenderer blocks={itemsAboutDrop?.title} className={styles.title} />
                    <div className={styles.contentBody}>
                        {
                            itemsAboutDrop?.items?.map((item, index)=>{
                                return (
                                    <Fragment key={index}>
                                    <div className={styles.contentList}>
                                        <div className={styles.number}>{index < 10 ? `0.${index+1}`:index}</div>
                                        <BlockContentRenderer blocks={item?.description} align={itemsAboutDrop?.alignText} className={styles.loremIpsumDolorContainer1}  />
                                    </div>
                                    <br/>
                                    </Fragment>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </article>
        </LayerContent>
    );
};

SectionAboutDrop.propTypes = {
    setArticleRef: PropTypes.any
};

export default SectionAboutDrop;