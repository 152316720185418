import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import "./calendar.css"

const Calendar = ({ onFunction, minDate }) => {

    let timeTyping = null;
    let timeWait = 800;

    const [ date, setDate ] = useState(0);
    const [ message, setMessage ] = useState(null);
    const [ isPop, setIsPop ] = useState(false);
    const isFirst = useRef(true);

    const validateDate = (val) => {
        setMessage(null);
        const selectedYear = new Date(val).getFullYear();
        const isCompleteYear = String(selectedYear).length === 4;
        const minEnterDate = new Date(minDate).getFullYear();
        if (isNaN(val)) {
            setMessage("Enter a valid date");
            return false;
        }
        if (!isCompleteYear || selectedYear < minEnterDate) {
            setMessage("The minimum allowed year is 1800");
            return false;
        }
        return val instanceof Date && !isNaN(val);
    };

    const handleChangeDate = (val) => {
        clearTimeout(timeTyping);
        isFirst.current = false;
        setDate(val);
    }

    const handleOnClose = () => {
        clearTimeout(timeTyping);
        setIsPop(false);
    };

    const handleOnOpen = () => {
        clearTimeout(timeTyping);
        setIsPop(true)
    }

    useEffect(()=>{
        clearTimeout(timeTyping);
        if(!isPop && !isFirst.current){
            const res = validateDate(date?.$d)
            if (res) {
                clearTimeout(timeTyping);
                timeTyping = setTimeout(()=>{
                    onFunction(date?.$d);
                    clearTimeout(timeTyping);
                    return null;
                },timeWait)
            }else{
                clearTimeout(timeTyping);
            }
        }
    },[date,isPop])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label={"Choose your birthday"} 
                slotProps={{
                    textField: {
                        helperText: message
                    }
                }}
                openTo="month"
                views={['month', 'year', 'day']}
                onChange={(newValue) => handleChangeDate(newValue)}
                onClose={handleOnClose}
                onOpen={handleOnOpen}
                value={date || null}
                minDate={minDate}
                // error={true}
                PopperProps={{
                    className: "popperCustom"
                }}
                sx={{
                    width: '250px',
                    ".MuiInputBase-root":{
                        background:'rgb(31, 35, 46)',
                    },
                    "&,label,input,div,span,fieldset,legend,svg": {
                        borderColor: 'var(--color-blanchedalmond)',
                        color: 'var(--color-blanchedalmond)',
                        fontFamily: 'var(--font-libre-franklin)'
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'var(--color-blanchedalmond)'
                        },
                        '&:hover fieldset': {
                            borderColor: 'var(--color-blanchedalmond)'
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'var(--color-blanchedalmond)'
                        }
                    },
                    "& label.Mui-focused": {
                        color: 'var(--color-blanchedalmond)'
                    },
                    "& label": {
                        color: 'var(--color-blanchedalmond)'
                    }
                }} 
                
            />
        </LocalizationProvider>
    );
};

const start = dayjs('1800-01-01');
const end = dayjs(new Date());

Calendar.defaultProps = {
    minDate: start,
    maxDate: end
}

Calendar.propTypes = {
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    onFunction: PropTypes.func
};

export default Calendar;
